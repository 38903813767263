import React from "react";
import { Typography } from "@material-ui/core";
import { PlayCircleOutline } from "@material-ui/icons";
import T from "prop-types";

const WatchVideo = (props) => {
  const { label } = props;

  return (
    <div className="wv-wrapper">
      <Typography className="wv-text">{label}</Typography>
      <PlayCircleOutline className="play-btn" />
    </div>
  );
};

WatchVideo.propTypes = {
  label: T.string,
};

WatchVideo.defaultProps = {
  label: "watch video",
};

export default WatchVideo;
