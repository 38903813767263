import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { ReactSVG } from "react-svg";

import "./styles.scss";
import { useSelector } from "react-redux";
const AuthLayout = ({ children }) => {
  const {settings} = useSelector((state) => state.app);
  return (
    <div className="screen-cntr">
      <div className="auth-wrapper">
        <Grid container className="height-100">
          <Grid item md={12} sm={12} lg={12}>
            <div className="auth-holder">
              <div className="flex-container">
                <div className="content-wrapper">
                  <div className="f-wrapper">
                    <div className="logo">
                      <img src={settings.clientLogo} alt="logo" />
                    </div>
                    <div className="f-title">Future-Ready Explorium</div>
                  </div>
                </div>
                <div className="auth-content-wrapper">{children}</div>
              </div>
            </div>
            <div className="logo-wrapper">
              <img
                className="desk-logo"
                src="/assets/Illuminebranding_Login_op.svg"
                alt="illumine_logo"
              />
              <img
                className="ipad-logo"
                src="/assets/login-illumine-branding-ipad.svg"
                alt="illumine_logo"
              />
            </div>
          </Grid>
        </Grid>
        {/* <ReactSVG className="login-radar" src="/assets/login-radar.svg" /> */}
      </div>
    </div>
  );
};

export default AuthLayout;
