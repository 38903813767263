import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Auth from "./containers/auth";
import Public from "./containers/public";
import App from "./containers/app";

import DefaultLayout from "./containers/_layouts/default";
import AuthLayout from "./containers/_layouts/auth";

import "./Main.css";
import "./Typography.css";

let Main = class Main extends Component {
  render() {
    const { auth } = this.props;
    const Layout = auth.isLoggedIn ? DefaultLayout : AuthLayout;
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.props.auth.isLoggedIn ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    const PublicRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          !this.props.auth.isLoggedIn ? (
            //<Component {...props} />
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          ) : (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }
      />
    );

    return (
      <Switch>
        <Route path="/auth" component={Auth} />
        <PrivateRoute path="/app" component={App} />
        <PublicRoute exact path="/dashboard" component={Public} />
        <PrivateRoute path="/app" component={App} />
      </Switch>
    );
  }
};

const mapStateToProps = (state) => ({ auth: state.auth });
export default withRouter(connect(mapStateToProps, null)(Main));
