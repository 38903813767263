import React, { useState } from "react";
import { Typography, Grid, Hidden, Box } from "@material-ui/core";
import {
  ArrowForwardIos,
  PlayCircleOutline,
  AccessTime,
} from "@material-ui/icons";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import ModalWrapper from "../Modal";
import VideoPlayer from "../VideoPlayer";
import WatchVideo from "../WatchVideo";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Radar from "../TestReport/radar";

import "./styles.scss";
import { useSelector } from "react-redux";

const Explore = () => {
  const [open, setOpen] = useState(false);
  const app = useSelector((state) => state.app);
  const { report } = useSelector((state) => state.app.userTestDetails) || {};
  const dimensions = [
    {
      label: "Ownership",
      link: "/explore/ownership/walkthrough",
    },
    {
      label: "Evolving <br/> Professionally",
      link: "/explore/evolving/walkthrough",
    },
    {
      label: "Responsiveness",
      link: "/explore/responsiveness/walkthrough",
    },
    {
      label: "Collaboration",
      link: "/explore/collaboration/walkthrough",
    },
    {
      label: "Becoming a <br/> Value Creator",
      link: "/explore/valuecreator/walkthrough",
    },
    {
      label: "Growing in <br/> Aspirations",
      link: "/explore/growing/walkthrough",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const openVideo = () => {
    setOpen(true);
  };

  const videos = app.settings["Zone2-Home"];

  return (
    <div className="explore-wrapper">
      <Grid container className="height-100 " spacing={2}>
        <Grid item lg={8} md={12} sm={12} className="explores-container">
          <div className="explores content-padding">
            <div className="heading">
              explore the 6 dimensions of future-readiness:
            </div>
            <Grid container>
              <Grid item md={6} lg={6} sm={12}>
                <div className="image-wrapper">
                  <Radar data={report} />
                </div>
              </Grid>
              <Grid item md={6} lg={6} sm={12} className="dims-grid">
                <div className="dims">
                  <Grid container spacing={3}>
                    {dimensions.map((d, i) => {
                      return (
                        <Grid key={`grid-${i}`} item lg={6} md={6} sm={6}>
                          <div className="e-links">
						  
                            <Typography className="e-links-heading">
                              Dimension:
                            </Typography>
						  
                            <Link to={d.link}>
                              <span
                                dangerouslySetInnerHTML={{ __html: d.label }}
                              />
                              <ArrowForwardIos />
                            </Link>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item lg={4} md={12} sm={12} className="height-100">
          <div
            className="explore-content-wrapper height-100 content-padding vp-bg-holder"
            onClick={openVideo}
            style={{
              backgroundImage: 'url("/assets/home-vp-bg.svg")',
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={12} md={6} sm={6}>
                <div className="zubin-wrapper">
                  <img src="/assets/Zubin-video-cover.png" alt="zubin-video" />
                  <PlayCircleOutline className="play-white" />
                </div>
              </Grid>
              <Grid item lg={12} md={6} sm={6}>
                <Typography className="video-heading">
                  The journey of building the <br /> Future-Readiness Radar
                </Typography>
                <Typography className="video-caption">
                  ZUBIN PALIA, Chief Group IR & Group HR, on behalf of the Lab
                </Typography>
              </Grid>
            </Grid>
            {/* <ReactSVG src="/assets/home-vp-bg.svg" className="vp-bg" /> */}
            <Hidden mdDown>
              <div className="coming-soon">
                <PlayCircleOutline />
                Watch Video
              </div>
            </Hidden>
          </div>
        </Grid>
      </Grid>
      {videos && videos.ZubinVideoUrl !== "video url" && (
        <ModalWrapper open={open} handleClose={handleClose}>
          <VideoPlayer
            mediaUrl={videos.ZubinVideoUrl}
            imgUrl={videos.ZubinVideoCover}
          />
        </ModalWrapper>
      )}
    </div>
  );
};

export default Explore;
