import React from "react";
import SupportComponent from "../SupportComponent";

import data from "./data";

const KnowYourChamps = () => {
  const caption =
    "As all of you start on your journey of future-readiness, culture champs will be there to support you in different ways –in case doubt, query, help, upcoming schedules and any other, reach out to the culture champ of your department. In addition to Culture Champs, Culture Lab will also be available to support both you and your culture champs.";

  React.useEffect(() => {
    document.getElementById("header").scrollIntoView(true);
  }, []);
  return (
    <div className="connect-with-wrapper">
      <SupportComponent
        data={data}
        caption={caption}
        lHead="Department"
        rHead="Culture Champion"
      />
    </div>
  );
};

export default KnowYourChamps;
