import React, { useEffect, useState } from "react";
import EvolveLayout from "../Evolve";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import { ReactSVG } from "react-svg";
import { Typography, Grid } from "@material-ui/core";

import ModalWrapper from "../Modal";

// import { ReactComponent as GrowingSVG } from "./Reflection-GrowingAspirations.svg";
// import { ReactComponent as CollaborationSVG } from "./Reflection-Collaboration.svg";
// import { ReactComponent as EvolveSVG } from "./Reflection-EvolveProf.svg";
// import { ReactComponent as OwnershipSVG } from "./Reflection-Ownership.svg";
// import { ReactComponent as ResponsivenessSVG } from "./Reflection-Responsiveness.svg";
// import { ReactComponent as ValueCreatorSVG } from "./Reflection-ValueCreator.svg";

import "./styles.scss";
import ImageSlide from "../ImageSlide";
import {updateUserEngagement} from "../../containers/app/redux_actions";

const { innerWidth: WinWidth, innerHeight: WinHeight } = window;
const slideHeight = WinHeight - 64;
const slideWidth = (WinWidth * 1920) / 1081;

const SelfCheck = () => {
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState(null);
  const [presentDimension, setPresentDimension] = useState(null);
  const {user_uid} = useSelector((state) => state.auth);
  const { dimension } = useParams();
  const app = useSelector((state) => state.app);
  const dimensions = app.dimensions;

  const reflectClickHandler = (url) => {
    setMedia(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const titles = {
    ownership: "Are you taking ownership?",
    growing: "Are you growing your aspirations?",
    responsiveness: "Are you improving your responsiveness?",
    collaboration: "Are you collaborating effectively?",
    valuecreator: "Are you becoming a value creator?",
    evolving: "Are you evolving professionally?",
  };

  useEffect(() => {
    const presentDimension = _.find(dimensions, (d) => {
      return d.id === dimension;
    });

    if (presentDimension) {
      setPresentDimension(presentDimension);
    }
  }, []);

  const reflects = {
    ownership: {
      0: "Role Ownership",
      1: "End-to-end Ownership",
      2: "Firm Ownership",
    },
    growing: {
      0: "Positively embracing challenges",
      1: "Proactively seeking stretch commitments",
      2: "Committing to quantum leaps",
    },
    responsiveness: {
      0: "Functional responsiveness",
      1: "Whole responsiveness",
      2: "Enablement-based responsiveness",
    },
    collaboration: {
      0: "Trust communication ",
      1: "Collaborative Action",
      2: "Co-creation",
    },
    valuecreator: {
      0: "Delivered Value Creator",
      1: "Adaptive Value Creator",
      2: "Transformative Value Creator",
    },
    evolving: {
      0: "Evolving one’s role",
      1: "Evolving in one’s practice",
      2: "Evolving holistically",
    },
  };

  const selfs = (presentDimension && presentDimension.selfassessment) || {};
  return (
    <EvolveLayout>
      <div className="self-reflect-wrapper">
        <div className="height-100 dimensions-svg">
          <Typography className="reflection-heading">
            Reflect: {titles[dimension]}
          </Typography>
          <div className="grids-wrapper">
            {presentDimension && (
              <Grid container spacing={4}>
                {Object.keys(selfs).map((s, i) => {
                  const key = i === 0 ? "a" : i === 1 ? "b" : "c";
                  const l = i + 1;
                  return (
                    <Grid item lg={4} md={4} sm={12} key={i}>
                      <div className="reflects-wrapper">
                        <Typography className="level-n">Level {l}</Typography>
                        <Typography className="level-t">
                          {reflects[dimension][i]}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item lg={12} md={12} sm={6}>
                            <div
                              className="reflections self"
                              onClick={(e) =>
                                reflectClickHandler(selfs[key].url)
                              }
                            >
                              <ReactSVG
                                className="r-icon"
                                src="/assets/self-reflect-icon.svg"
                              />
                              View Self <br /> Reflection L{l}
                            </div>
                          </Grid>
                          <Grid item lg={12} md={12} sm={6}>
                            <div
                              className="reflections leader"
                              onClick={(e) => {
                                updateUserEngagement({uid: user_uid, dim:dimension, type:"selfCheck", unit: key});
                                  reflectClickHandler(selfs[key].urlleader);
                                }
                              }
                            >
                              <ReactSVG
                                className="r-icon"
                                src="/assets/self-reflect-icon.svg"
                              />
                              View Leadership <br /> Reflection L{l}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </div>
        </div>
        {media && (
          <ModalWrapper open={open} handleClose={handleClose}>
            <ImageSlide
              imgUrl={media}
              dimensions={{ slideHeight, slideWidth }}
            />
          </ModalWrapper>
        )}
      </div>
    </EvolveLayout>
  );
};

export default SelfCheck;
