import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import store from "../../Redux_Store";

const config = {
  apiKey: "AIzaSyC8milMT7C19VzlGkejXNkinyKfYAkuir8",
  authDomain: "tatasteel-futurebuilder-board.firebaseapp.com",
  databaseURL: "https://tatasteel-futurebuilder-board.firebaseio.com",
  projectId: "tatasteel-futurebuilder-board",
  storageBucket: "tatasteel-futurebuilder-board.appspot.com",
  messagingSenderId: "491058618302",
  appId: "1:491058618302:web:4911150ddde39ac4fce3ef",
  measurementId: "G-EYCE0H41KJ",
};

let dataLayer = window.dataLayer || [];

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let db = firebase.database();
let fa = firebase.analytics();

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    let uid = user.uid;
    dataLayer.push({userId: uid});
    fa.setUserId(uid)
    // console.log(fa);
    db.ref(`/dimensions`).on("value", (snapshot) => {
      let dimensions = snapshot.val();
      store.dispatch({ type: "SET_DIMENSION_DETAILS", dimensions });
    });

    db.ref(`/tests/fyidom`).on("value", (snapshot) => {
      let testDetails = snapshot.val();
      store.dispatch({ type: "SET_TEST_DETAILS", testDetails });
      Object.keys(testDetails?testDetails.questions:{}).forEach((qId) => {
        db.ref(`questionBank/${qId}`).on("value", (qSnapshot) => {
          let qDetail = qSnapshot.val();
          store.dispatch({
            type: "SET_QUESTION_DETAILS",
            data: { qId, qDetail },
          });
        });
      });
    });

    db.ref(`users/${uid}/engagements/tests/fyidom`).on("value", (snapshot) => {
      let userTestDetails = snapshot.val();
      store.dispatch({ type: "SET_USER_TEST_DETAILS", userTestDetails });
    });
  } else {
    console.log("no user");
  }
});

export const updateUserTestConfig = ({ uid, testId, config }) => {
  let updates = {};
  updates[`/users/${uid}/engagements/tests/${testId}/config`] = config;
  return db.ref().update(updates);
};

export const updateUserResponse = ({ queId, response }) => {
  return (dispatch) => {
    dispatch({ type: "SET_USER_RESPONSE", data: { queId, response } });
  };
};

export const updateLastVist = ({uid, testId})=>{
  let updates = {};
  updates[`/users/${uid}/engagements/tests/${testId}/lastVisitedReportOn`] = Date.now();
  return db.ref().update(updates);
}

export const updateUserTestResponse = ({ uid, testId, response }) => {
  let updates = {};
  updates[`/users/${uid}/engagements/tests/${testId}/`] = response;
  return db.ref().update(updates);
};

export const updateUserEngagement = ({ uid, dim, type, unit }) => {
  let updates = {};
  updates[`/users/${uid}/engagements/units/${dim}/${type}/${unit}`] = Date.now();
  return db.ref().update(updates);
};

export const updateTestCompletion = () => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_TEST_PROGRESS", data: { isDone: true } });
  };
};

export const jumpToQuestion = ({ oId }) => {
  return (dispatch) => {
    dispatch({ type: "JUMP_TO_QUESTION", data: { oId } });
  };
};

export const updateReviewOn = ({ on }) => {
  return (dispatch) => {
    dispatch({ type: "SET_REVIEW", data: { on } });
  };
};
