import React from "react";
import {
  Input,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Link,
  Grid,
} from "@material-ui/core";

import "./Registration.scss";


class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      fullname: "",
      password: "",
      cPassword: "",
      accesscode: "",
      showPassword: false,
    };

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }
  render() {
    const { classes } = this.props;
    console.log(this.props);
    return (
      <div className="auth-form-cntr rform-wrapper">
        <Grid container className="container container-1">
          <Grid item md={12}>
            <Typography className="form-title">Register</Typography>
          </Grid>
        </Grid>

        <Grid container className="container container-2">
          <Grid item md={12}>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="fullname">Full Name</InputLabel>
              <Input
                id="fullname"
                label="Full Name"
                type="text"
                autoComplete="on"
                value={this.state.fullname}
                onChange={this.handleChange("fullname")}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="container container-3">
          <Grid item md={12}>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="company-email-id">
                Company Email ID
              </InputLabel>
              <Input
                id="company-email-id"
                label="Password"
                autoComplete="off"
                onChange={this.handleChange("email")}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="container container-4">
          <Grid item md={12}>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="set-password">Set Password</InputLabel>
              <Input
                id="set-password"
                label="Set Password"
                type="password"
                value={this.state.password}
                onChange={this.handleChange("password")}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="container container-5">
          <Grid item md={12}>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="confirm-password">
                Confirm Password
              </InputLabel>
              <Input
                id="confirm-password"
                label="Confirm Password"
                type="password"
                value={this.state.cPassword}
                onChange={this.handleChange("cPassword")}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="container container-6">
          <Grid item md={12}>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="access-code">Personnel Number</InputLabel>
              <Input
                id="access-code"
                label="Personnel Number"
                type="text"
                value={this.state.accesscode}
                onChange={this.handleChange("accesscode")}
              />
            </FormControl>
          </Grid>
        </Grid>
        {this.props.authMsg && (
          <Grid container className="container container-7">
            <Grid item md={12}>
              <div
                style={{
                  height: 64,
                  color: "#f44336",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.props.authMsg}
              </div>
            </Grid>
          </Grid>
        )}
        <Grid container className="container container-8">
          <Grid item md={8} className="">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="submit-btn"
              onClick={() => {
                let {
                  email,
                  password,
                  cPassword,
                  accesscode,
                  fullname,
                } = this.state;
                this.props.registerAttempt({
                  email,
                  password,
                  cPassword,
                  accesscode,
                  fullname,
                  successCallBack: () => {
                    // console.debug("success");
                  },
                  failureCallback: () => {
                    // console.log("failed");
                  },
                });
              }}
            >
              Submit
            </Button>
            <Link href="/auth/login" className="login-link" underline="none">
              Already a user? Login
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Registration;
