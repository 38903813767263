import React from "react";
import { Dialog, DialogContent, Box } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import "./styles.scss";

const ModalWrapper = (props) => {
  const { open, handleClose, children, showCancel } = props;

  return (
    <Dialog
      classes={{
        root: "dialog-root",
        container: "dialog-container",
        paper: "dialog-paper",
        paperWidthMd: "dialog-paperWidthMd",
      }}
      className="dialog"
      open={open}
      onClose={handleClose}
      // fullWidth={false}
      maxWidth={"md"}
    >
      <DialogContent className="dialog-content">
        <Box hidden={showCancel}>
          <Cancel className="close-btn" onClick={handleClose} />
        </Box>
        {children}
      </DialogContent>
    </Dialog>
  );
};

ModalWrapper.defaultProps = {
  showCancel: false,
};

export default ModalWrapper;
