import React, { useState } from "react";
import { AccountCircle } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ReactStopWatch from "react-stopwatch";
import _ from "lodash";

import "./styles.scss";
import { Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ModalWrapper from "../../../components/Modal";
import SaveExit from "../../../components/SaveExit";
import AvatarWithUsername from "../../../components/AvatarWithUsername";

import { jumpToQuestion } from "../../app/redux_actions";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

const AssessmentLayout = (props) => {
  let { children } = props;
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const today = new Date();
  const todaysDate = `${today.getDate()} / ${
    today.getMonth() + 1
  } / ${today.getFullYear()}`;

  const isReport = pathname === "/report" ? true : false;

  const padStart = (num) => {
    return num.toString().padStart(2, 0);
  };

  const handleSaveExit = () => {
    setOpen(true);
  };

  const jumpTo = (e, n) => {
    dispatch(jumpToQuestion({ oId: n }));
  };

  const userTestDetails = app.userTestDetails || {};
  const questions = userTestDetails.config && userTestDetails.config.questions;
  let currentSection =
    userTestDetails.config && userTestDetails.config.currentSection
      ? userTestDetails.config.currentSection
      : null;
  const currentSectionQuestions =
    userTestDetails.config && userTestDetails.config.questions
      ? userTestDetails.config.questions[currentSection]
      : {};
  const questionsLength = Object.keys(currentSectionQuestions).length;

  let count = 0;
  let flag = true;

  for (const key in questions) {
    if (questions.hasOwnProperty(key)) {
      const element = questions[key];
      if (flag) {
        count += Object.keys(element.questions).length;
        if (key === userTestDetails.config.currentSection) {
          flag = false;
        }
      }
    }
  }

  let jArray = [];
  for (let j = count - questionsLength + 1; j <= count; j++) {
    jArray.push(j);
  }

  return (
    <div className={clsx("assessment-layout", { "report-layout": isReport })}>
      <div className="heading">
        {(!isReport || !app.isTestCompleted) && (
          <Button
            className="save-exit-btn"
            onClick={handleSaveExit}
            underline="none"
          >
            Save & Exit
          </Button>
        )}
        <div className="profile">
          <AvatarWithUsername />
        </div>

        <div className="head-title">Future-Readiness Cognitive Test</div>
      </div>
      <div className="assessment-container-wrapper">
        {/* {app.reviewOn && (
          <div className="jump-to-q-wrapper">
            <div className="j-title">Jump to Q</div>
            {jArray.map((n, i) => {
              return (
                <div className="j-question" onClick={(e) => jumpTo(e, i)}>
                  {n}
                </div>
              );
            })}
          </div>
        )} */}
        <div className="assessment-container">{children}</div>
        {isReport && (
          <div className="report-close-btn">
            <Button
              className="btn"
              variant="contained"
              color="primary"
              size="large"
              href="/map"
            >
              Close
            </Button>
          </div>
        )}
      </div>
      {/* {!isReport && (
        <div className="test-timer">
          <Typography className="time">
            <ReactStopWatch
              minutes={0}
              seconds={0}
              render={({ minutes, seconds }) => {
                return `${padStart(minutes)} : ${padStart(seconds)}`;
              }}
            />
          </Typography>
          <Typography className="date">{todaysDate}</Typography>
        </div>
      )} */}

      <div className="logo-wrapper">
        <img
          src="/assets/illumine-branding-assessment.svg"
          alt="illumine_logo"
        />
      </div>
      <ModalWrapper
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      >
        <SaveExit />
      </ModalWrapper>
    </div>
  );
};

export default AssessmentLayout;
