import React, { useState, useEffect } from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Slideshow from "../slideshow";
import _ from "lodash";

import EvolveLayout from "../Evolve";
import ImageSlide from "../ImageSlide";
import Modal from "../Modal";
import "./styles.scss";
import {updateUserEngagement} from "../../containers/app/redux_actions";

const { innerWidth: WinWidth, innerHeight: WinHeight } = window;
const slideHeight = WinHeight - 64;
const slideWidth = (WinWidth * 1920) / 1081;
const CaseExamples = () => {
  const { dimension } = useParams();
  const [caseExample, setCaseExample] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [caseEx, setCase] = useState(null);
  const app = useSelector((state) => state.app);
  const {user_uid} = useSelector((state) => state.auth);
  const dimensions = app.dimensions;
  const getBackGroundColor = (value) => {
    const key = value.includes("Level 1:")
      ? "Level 1"
      : value.includes("Level 2:")
      ? "Level 2"
      : value.includes("Level 3:")
      ? "Level 3"
      : "";

    switch (key) {
      case "Level 1":
        return "#FF8A4D";
      case "Level 2":
        return "#D8CA12";
      case "Level 3":
        return "#48B6B7";
      default:
        return "#48B6B7";
    }
  };

  const titles = {
    ownership: "Ownership",
    growing: "Growing Aspirations",
    responsiveness: "Responsiveness",
    collaboration: "Collaboration",
    valuecreator: "Value Creation",
    evolving: "Evolving Professionally",
  };

  useEffect(() => {
    const presentDimension = _.find(dimensions, (d) => {
      return d.id === dimension;
    });

    if (presentDimension) {
      setCaseExample(presentDimension);
      setOpen(false);
    }
  }, []);

  return (
    caseExample && (
      <EvolveLayout>
        <div className="case-examples-wrapper">
          <div className="leadership-wrapper">
            <Typography className="title">
              Case Stories of {titles[dimension]} in action
            </Typography>
            <div className="grids-wrapper">
              <Grid container className="" spacing={4}>
                {Object.keys(caseExample.cases).map((l, i) => {
                  let level = caseExample.cases[l];
                  return (
                    <Grid
                      item
                      md={6}
                      lg={6}
                      sm={12}
                      key={i}
                      className="l-click"
                      onClick={() => {
                        updateUserEngagement({uid: user_uid, dim:dimension, type:"cases", unit: l});
                        setCase(level);
                        setImgUrl(level.url);
                        setOpen(true);
                      }}
                    >
                      <Grid container className="justify-center">
                        <Grid item md={12} lg={12} sm={12}>
                          <div className="level">
                            <div className="level-left-section">
                              <div
                                className="level-icon-wrapper"
                                style={{
                                  backgroundColor: getBackGroundColor(
                                    level.title
                                  ),
                                }}
                              >
                                <img src="/assets/case.svg" alt="level-icon" />
                              </div>
                            </div>
                            <div className="level-right-section">
                              <Typography className="right-title">
                                {level.title}
                              </Typography>
                              <Typography className="right-caption">
                                {level["Main title"]}
                              </Typography>
                              <div className="view-link">View</div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
          <Modal
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
          >
            {caseEx && caseEx.type === "pdf" ? (
              <Slideshow
                unit={{
                  mediaUrl: caseEx.url,
                  type: "pdf",
                  docRatio: caseEx.docRatio,
                  dimensions: { slideHeight: 530, slideWidth: 993 },
                }}
              />
            ) : (
              <ImageSlide
                imgUrl={imgUrl}
                dimensions={{ slideHeight, slideWidth }}
              />
            )}
          </Modal>
        </div>
      </EvolveLayout>
    )
  );
};

export default CaseExamples;
