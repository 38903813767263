import React, { Component } from "react";
import T from "prop-types";
// import { Redirect } from "react-router";
import { Redirect, Route, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import DefaultLayout from "../_layouts/default";
import AuthLayout from "../_layouts/auth";

class RouteWrapper extends Component {
  render() {
    // console.log(this.props);
    const {
      isPrivate,
      component: Component,
      auth,
      layout,
      ...rest
    } = this.props;

    if (isPrivate && !auth.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/auth/login",
          }}
        />
      );
    }

    let Layout = auth.isLoggedIn
      ? layout
        ? layout
        : DefaultLayout
      : AuthLayout;
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
}

RouteWrapper.propTypes = {
  isPrivate: T.bool,
  component: T.oneOfType([T.element, T.func, T.any]).isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

const mapStateToProps = (state) => ({ auth: state.auth, app: state.app });
export default withRouter(connect(mapStateToProps, null)(RouteWrapper));
