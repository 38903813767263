import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch, connect } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import _ from "lodash";

import "./styles.scss";
import {
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  useRadioGroup,
} from "@material-ui/core";
import Modal from "../Modal";
import {Link} from "react-router-dom";

import { ReactComponent as Tick } from "./completed-tick.svg";
import {
  updateUserTestResponse,
  updateUserResponse,
  updateUserTestConfig,
  updateTestCompletion,
  updateReviewOn,
} from "../../containers/app/redux_actions";
import ReviewCheck from "../ReviewCheck";

const TestAssessment = (props) => {
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [optionDisplayed, setOptionDisplayed] = useState(false);
  const [open, setOpen] = useState(false);
  const [reviewPrompt, setReviewPrompt] = useState(false);
  const [review, setReview] = useState(false); //This is a gatekeepr set to true
  const [_continue, setContinue] = useState(false);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);

  let testDetails = app.testDetails;
  let questions =
    testDetails && testDetails.questions ? testDetails.questions : null;
  let questionDetails = app.questionDetails || {};
  let userTestDetails = app.userTestDetails || {};
  let currentSection = userTestDetails.config
    ? userTestDetails.config.currentSection
    : null;
  let currentQuestionList =
    userTestDetails.config && userTestDetails.config.questions
      ? userTestDetails.config.questions[currentSection].questions
      : [];
  let currentQuestionOrder = userTestDetails.config
    ? userTestDetails.config.currentQuestionOrder
    : null;
  let quesIdOrders = Object.keys(currentQuestionList).sort((a, b) => {
    return currentQuestionList[a].order - currentQuestionList[b].order;
  });
  let currentQuestion = quesIdOrders[currentQuestionOrder];
  let activeQuestion = currentQuestion
    ? questionDetails[currentQuestion]
    : null;
  const questionsLength = quesIdOrders.length;

  if (
    !userTestDetails ||
    !userTestDetails.config ||
    !Object.keys(userTestDetails.config).length
  ) {
    const uid = auth.user.uid;
    const testId = app.testId;
    let config = {};
    config.startedOn = Date.now();
    config.currentSection = "sec1";
    let lvls = ["lvl1", "lvl2", "lvl3"];
    let sections = {};
    let _sections = [];
    let questionCount = 0;
    lvls.forEach((lvl, i) => {
      _sections[i] = shuffleArray(
        Object.keys(questions).filter((el) => {
          return questions[el].lvl == lvl;
        })
      );
    });

    _sections.forEach((s, i) => {
      sections[`sec${i + 1}`] = { order: i + 1, questions: {} };
      s.forEach((q, j) => {
        if (j == 0) {
          // config.currentQuestion = q;
          config.currentQuestionOrder = j;
        }
        sections[`sec${i + 1}`].questions[q] = { order: j + 1 };
        questionCount++;
      });
    });
    config.totalNumberOfQuestions = questionCount;
    config.currentlyRunningQuestion = 1;
    config.questions = { ...sections };
    updateUserTestConfig({ uid, testId, config });
  }
  const startNextSection = () => {
    const uid = auth.user.uid;
    const testId = app.testId;
    const config = userTestDetails.config;
    const section = config.questions[currentSection];
    const nextSection = _.find(
      config.questions,
      (q) => q.order === section.order + 1
    );
    const nextSectionKey = _.findKey(
      config.questions,
      (q) => q.order === section.order + 1
    );

    if (nextSection && nextSection.questions) {
      const questionsList = nextSection.questions;

      const _quesIdOrders = Object.keys(questionsList).sort((a, b) => {
        return questionsList[a].order - questionsList[b].order;
      });

      const _currentQuestion = _quesIdOrders[0];
      activeQuestion = _currentQuestion
        ? questionDetails[currentQuestion]
        : null;

      config.currentSection = nextSectionKey;
      config.currentQuestionOrder = 0;
      // config.currentQuestion = _currentQuestion;

      updateUserTestConfig({ uid, testId, config });
    }
  };

  const handleNext = () => {
    //First sync local response to global
    const uid = auth.user.uid;
    const testId = app.testId;
    const queId = activeQuestion.qId;
    updateUserTestResponse({ uid, testId, queId, response: userTestDetails });
    let x = userTestDetails.config.currentlyRunningQuestion;
    if (x < userTestDetails.config.totalNumberOfQuestions) {
      userTestDetails.config.currentlyRunningQuestion = x + 1;
    } else if(x == userTestDetails.config.totalNumberOfQuestions&&!_continue) {
      // userTestDetails.config.currentlyRunningQuestion = null;
      userTestDetails.config.isTestCompleted = true;
      userTestDetails.config.endedOn = Date.now();
    }

    //Decide where to go next

    if (currentQuestionOrder === quesIdOrders.length - 1 && !_continue) {
      setReviewPrompt(true);
      console.log("this is te last ques of the sections");
    } else {
      setContinue(false);
      userTestDetails.config.currentQuestionOrder = currentQuestionOrder + 1;
    }
    updateUserTestResponse({ uid, testId, queId, response: userTestDetails });
    setOptionDisplayed(null);
  }

  const handlePrev = () => {
    const uid = auth.user.uid;
    const testId = app.testId;
    const queId = activeQuestion.qId;
    userTestDetails.config.currentQuestionOrder = currentQuestionOrder - 1;
    userTestDetails.config.currentQuestion =
      quesIdOrders[currentQuestionOrder - 1];
    let x = userTestDetails.config.currentlyRunningQuestion;
    userTestDetails.config.currentlyRunningQuestion = x - 1;

    updateUserTestResponse({ uid, testId, queId, response: userTestDetails });
    setOptionDisplayed(null);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const SortableItem = SortableElement(({ value }) => <li>{value}</li>);

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((value, index) => {
          return (
            <SortableItem key={`item-${value}`} index={index} value={value} />
          );
        })}
      </ul>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {};

  const getOptionTypes = ({ options, userTestDetails }) => {
    let _res = userTestDetails.responses
      ? userTestDetails.responses[currentQuestion] || null
      : null;
    if(!optionDisplayed){
      setOptionDisplayed(shuffleArray(Object.keys(options)))
    }
    switch (activeQuestion.type) {
      case "reorder_":
        return (
          <SortableList
            items={Object.keys(activeQuestion.options)}
            onSortEnd={onSortEnd}
          />
        );

      default:
        return (
          <RadioGroup
            aria-label="options"
            name="options"
            value={_res || null}
            onChange={(ev) => {
              const queId = currentQuestion;
              const response = ev.target.value;
              props.updateUserResponse({ queId, response });
            }}
          >
            {(optionDisplayed||[]).map((k, i) => {
              let o = options[k];
              return (
                <FormControlLabel
                  key={k}
                  value={k}
                  control={<Radio />}
                  label={o.text}
                  className="question-option"
                />
              );
            })}
          </RadioGroup>
        );
    }
  };

  const reviewTest = () => {
    setReviewPrompt(false);
    setReview(true);
    setContinue(false);
    dispatch(updateReviewOn({ on: true }));
  };

  const continueTest = () => {
    setReviewPrompt(false);
    setReview(false);
    setContinue(true);
    startNextSection();

    if (jArray[currentQuestionOrder] === Object.keys(questions).length) {
      dispatch(updateTestCompletion());
    }
  };

  const closeReview = () => {
    setReview(true);
    dispatch(updateReviewOn({ on: false }));
  };

  // To maintain Question Sequence
  let count = 0;
  let flag = true;
  if (userTestDetails.config && userTestDetails.config.questions) {
    for (const key in userTestDetails.config.questions) {
      if (userTestDetails.config.questions.hasOwnProperty(key)) {
        const element = userTestDetails.config.questions[key];
        if (flag) {
          count += Object.keys(element.questions).length;
          if (key === userTestDetails.config.currentSection) {
            flag = false;
          }
        }
      }
    }
  }

  let jArray = [];
  for (let j = count - questionsLength + 1; j <= count; j++) {
    jArray.push(j);
  }
  return (
    <div className="test-assessment-wrapper">
      {app.isTestCompleted && _continue ? (
        <div className="test-completed">
          <div className="congrats-icon">
            <Tick />
          </div>
          <Typography className="title">Congratulations!</Typography>
          <Typography className="caption">
            The assessment is complete!{" "}
          </Typography>
          <Button
            component={Link}
            className="view-report-btn"
            size="large"
            color="primary"
            variant="contained"
            to="/report"
          >
            View Report
          </Button>
        </div>
      ) : !reviewPrompt ? (
        activeQuestion && (
          <div className="test-progress">
            <div>
              <Typography className="align-center test-count">
                {jArray[currentQuestionOrder]} / {Object.keys(questions).length}
              </Typography>

              <Typography className="question">
                {activeQuestion.qText}
              </Typography>
              <Typography className="caption">
                {activeQuestion.trigger}
              </Typography>
              {activeQuestion &&
                getOptionTypes({
                  options: activeQuestion.options,
                  userTestDetails,
                })}
            </div>
            <div className="btn-wrapper">
              <Box visibility={currentQuestionOrder > 0 ? "visible" : "hidden"}>
                <Button className="r-links" onClick={handlePrev}>
                  Prev
                </Button>
              </Box>
              <Box>
                {/* {app.reviewOn && (
                  <Link className="r-links" onClick={closeReview}>
                    Close Review & Continue Test
                  </Link>
                )} */}

                <Button
                  className="submit-btn"
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    userTestDetails.responses
                      ? !userTestDetails.responses[currentQuestion]
                      : true
                  }
                >
                  {selectedQuestion < Object.keys(questions).length
                    ? "Next"
                    : "End Assessment"}
                </Button>
              </Box>
            </div>
          </div>
        )
      ) : (
        <ReviewCheck
          currentSectionQuestions={currentQuestionList.length}
          reviewTest={reviewTest}
          continueTest={continueTest}
        />
      )}
      <Modal open={open} handleClose={handleClose}>
        <ReviewCheck reviewTest={reviewTest} continueTest={continueTest} />
      </Modal>
    </div>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    { updateUserTestResponse, updateUserResponse },
    dispatch
  );
}

export default connect(null, matchDispatchToProps)(TestAssessment);

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
