import React from "react";
import { Typography, Link } from "@material-ui/core";

import "./styles.scss";

const Footer = () => {
  return (
    <div className="footer-layout">
      <Typography className="f-support">
        Facing a technical issue? Write-in to
        <Link
          className="span"
          href="mailto:'support@futurereadyxp.in'"
          underline="none"
        >
          {" "}
          support@futurereadyxp.in
        </Link>
      </Typography>
      <div className="f-collab">
        <div className="logo-wrapper">
                <img
                  src="/assets/header-illumine-logo.svg"
                  alt="illumine_logo" height={64}
                />
              </div>
        {/* A <span className="span">Tata Steel Culture Lab</span> +{" "}
        <span className="span">Illumine</span> Collaboration. */}
      </div>
    </div>
  );
};

export default Footer;
