import React, { useState } from "react";
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import _ from "lodash";

import "./styles.scss";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
// SVGs
import { ReactComponent as FutureBg } from "../TestAssessmentStart/Assessment_bg_image.svg";
import { useSelector } from "react-redux";

const MapFuture = () => {
  const [showRequirments, setShowRequirements] = useState(false);
  const app = useSelector((state) => state.app);
  const questions = _.values(app.testDetails.questions);
  const userTestDetais = _.values(app.userTestDetais);

  const steps = [
    "Take the Future-Readiness Cognitive Test",
    "Get your Future-Readiness Development Radar, and know areas for development",
    "Re-map yourself at the end of the intervention period of 1 year",
  ];

  const onRequirementsClick = () => {
    setShowRequirements(!showRequirments);
  };

  const isTestCompleted = (stepIndex) => {
    if (stepIndex === 0) {
      return true;
    } else if (stepIndex === 1) {
      if (userTestDetais.length < questions.length) {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <div className="map-future">
      <Grid container className="container">
        <Grid item md={12}>
          <div className="map-future-wrapper">
            {app.isTestStarted && !app.isTestCompleted &&  (
              <Grid container className="container">
                <Grid item md={12} lg={12}>
                  <div
                    className="test-completed"
                    style={{
                      backgroundImage: "url('/assets/Assessment_bg_image.svg')",
                      backgroundPosition: "bottom right",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Typography className="message">
                      Would you like to finish the test <br /> & generate your
                      report?
                    </Typography>
                    <div md={12} className="btn-apart">
                      <Button
                        component={Link}
                        variant="contained"
                        size="large"
                        to="/start-assessment"
                        className="btn"
                      >
                        continue test
                      </Button>
                    </div>
                    {/* <FutureBg className="bg-image" /> */}
                  </div>
                </Grid>
              </Grid>
            )}
            {app.isTestCompleted && (
              <Grid container className="container">
                <Grid item md={12} lg={12}>
                  <div
                    className="test-completed"
                    style={{
                      backgroundImage: "url('/assets/Assessment_bg_image.svg')",
                      backgroundPosition: "bottom right",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Typography className="message">
                      Congrats! You have completed the Future-Readiness
                      Cognitive Test. Zone 2 has been unlocked.
                    </Typography>
                    {/* <Grid container spacing="6">
                      <Grid lg={3} md={3} sm={12}>
                        <Button
                          component={Link}
                          variant="contained"
                          size="large"
                          to="/report"
                          className="btn"
                        >
                          View Report
                        </Button>
                      </Grid>
                      <Grid lg={9} md={9} sm={12}>
                        <Button
                          component={Link}
                          variant="contained"
                          size="large"
                          to="/explore"
                          className="btn"
                        >
                          explore the future-readiness dimensions
                        </Button>
                      </Grid>
                    </Grid> */}
                    <div className="btn-apart">
                      <Button
                        component={Link}
                        variant="contained"
                        size="large"
                        to="/report"
                        className="btn view"
                      >
                        View Report
                      </Button>
                      <Button
                        component={Link}
                        variant="contained"
                        size="large"
                        to="/explore"
                        className="btn explore"
                      >
                        explore future-readiness dimensions
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container className="container">
              <Grid item md={7} lg={7}>
                <div className="content-wrapper">
                  <Typography className="medium-text text">
                    In this zone, you can take the ‘Future-Readiness Cognitive
                    Test’. This test will help you map the areas in which you
                    need to develop yourself, in order to become future-ready.
                  </Typography>
                  <Typography className="regular-text text">
                    On completion of the Test, you will get your Report in form
                    of a Future-Readiness Development Radar. This will become
                    your self-development guide. It will indicate areas that you
                    need to develop yourself in, to become Future Ready.
                  </Typography>
                  <Typography className="regular-text text">
                    At the end of the intervention period of 1 year, you can re-map yourself to
                    see where you have improved, and continue on your
                    development journey.
                  </Typography>
                </div>
              </Grid>
              <Grid item md={5} lg={5}>
                <div className="content-wrapper road-map">
                  <Typography className="rm-title">Your Roadmap:</Typography>

                  <div className="stepper-wrapper">
                    <Stepper orientation="vertical">
                      {steps.map((label, i) => (
                        <Step key={label} color="secondary" active={true}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
              </Grid>
            </Grid>
            {!app.isTestCompleted && !app.isTestStarted && (
              <Grid container className="container">
                <Grid item md={4} sm={12}>
                  <div className="section-left">
                    <Typography className="heading">
                      Taking the Test: <br /> What you'll need
                    </Typography>
                    <FutureBg className="bg-image" />
                  </div>
                </Grid>
                <Grid item md={8} sm={12} className="section-right-wrapper">
                  <div className="section-right requirements">
                    <ul className="list">
                      <li>
                        You can take this test without any prior reading/ study.
                      </li>
                      <li>The test will take ~ 75 min to complete.</li>
                      <li>
                        It is best done in a single flow – so select a time when
                        you are not likely to get disturbed.
                      </li>
                      <li>
                        Ensure you have a good internet connection and a
                        laptop/desktop/ iPad to use.
                      </li>
                      <li>
                        Your results will be valid till the end of the
                        intervention period of 1 year. During this period, you cannot retake the
                        test.
                      </li>
                    </ul>
                    <Button
                      component={Link}
                      className="take-btn"
                      color="primary"
                      variant="contained"
                      size="large"
                      to="/start-assessment"
                    >
                      i'm ready to take the test
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MapFuture;
