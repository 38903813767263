import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import AvatarWithUsername from "../../../components/AvatarWithUsername";
import { PlayArrow } from "@material-ui/icons";

import "./styles.scss";
const dimensions = {
  d001: "Collaboration",
  d002: "Ownership",
  d003: "Evolving Professionally",
  d004: "Becoming a Value Creator",
  d005: "Growing in Aspirations",
  d006: "Responsiveness",
};

const modes = [
  {
    title: "Conscious & Continuous practice mode:",
    caption:
      "In dimensions where your current way of thinking is in the right direction and already future- ready, conscious & continuous practice is recommended. To ensure a sustainable future-ready state, consciously practice the Future-ready Work Practices of level 3. Refer to Zone 2 of this Explorium for the same.",
  },
  {
    title: "Targeted reflection mode:",
    caption:
      "In dimensions where your current way of thinking is almost aligned to the future-ready thinking patterns, targeted reflection will help you achieve your development goal. With just 1 or 2 shifts in your thinking patterns, you can move to the next level.",
  },
  {
    title: "Deep engagement mode:",
    caption:
      "In dimensions where your current way of thinking needs to be more aligned to the future-ready thinking, deep engagement is recommended. In these dimensions, three or more of your responses in the cognitive test are not aligned. Engaging deeply with the enablers in Zone 3 of this Explorium will help in your development journey. ",
  },
];
function reflectionQuestions(d) {
  if (d.type == "trm") {
    return <div>Targeted Reflection</div>;
  }
  return d.type == "dem" ? (
    <div>Deep Engagement</div>
  ) : (
      <div>Conscious & Continuous Practice</div>
    );
}

const RadarFooter = () => {
  return (
    <div className="part-footer">
      <div>
        <Typography>
          Copyright 2020; Illumine. All rights reserved. The Future-Ready
          Cognitive Assessment is a proprietary measurement tool designed and
          developed by Illumine Knowledge Resources Pvt. Ltd.
        </Typography>
      </div>
      <div className="report-logo">
        <img src="/assets/logo-report.svg" alt="logo" />
      </div>
    </div>
  );
};

const ReportLayout = ({ children }) => {
  const app = useSelector((state) => state.app);
  const { user_fullname } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.app.userTestDetails);

  let d = new Date(config.startedOn);
  let TRs = app.userTestDetails?Object.keys(app.userTestDetails.targetedReflections||{}).filter(
    (el) => {
      return app.userTestDetails.targetedReflections[el].reflections;
    }
  ):{};
  return (
    <div key={config.startedOn} className="screen-cntr">
      <div className="report-layout">
        <div className="heading">
          <div>
            {" "}
            <Link className="b-btn" to="/dashboard">
              {" "}
              Back to Home{" "}
            </Link>{" "}
          </div>
          <div className="profile-top">
            {" "}
            <AvatarWithUsername />{" "}
          </div>
        </div>

        <div
          style={{
            backgroundColor: "white",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            borderRadius: 15,
            maxWidth: "833px",
            margin: "15px auto",
            padding: "65px 90px",
            width: "100%",
          }}
        >
          <Grid container className="section">
            <Grid item md={12} sm={12}>
              <div className="header">
                <div>
                  <img
                    src="/assets/tata_logo.png"
                    alt="tata-logo"
                    height="14px"
                  />
                </div>
                <div>
                  <Typography className="title">
                    FUTURE-READINESS DEVELOPMENT REPORT
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className="top-border section">
            <Grid item lg={6} md={6} sm={6}>
              <Typography className="head-title">Name</Typography>
              <Typography className="profile">{user_fullname}</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6}>
              <Typography className="head-title">Taken On</Typography>
              <Typography className="profile">{`${d.getDate()}/${d.getMonth() + 1
                }/${d.getFullYear()}`}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className="top-border section"
            style={{ marginTop: "65px" }}
          >
            <Grid item lg={12} md={12}>
              <Typography className="part1-h">
                The Report is organized into 2 parts:
              </Typography>

              <a className="nlinks" href="#part1">
                <PlayArrow className="play" /> <span>part 1: &nbsp;</span>Your
                Future-Readiness Development Radar
              </a>
              <a className="nlinks" href="#part2">
                <PlayArrow className="play" /> <span>part 2: &nbsp;</span>
                Targeted Self-development
              </a>
            </Grid>
          </Grid>
        </div>

        <div
          id="part1"
          style={{
            backgroundColor: "white",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            borderRadius: 15,
            maxWidth: "833px",
            minHeight: 500,
            margin: "30px auto",
            padding: "65px 90px",
          }}
        >
          <Typography className="s-heading">
            part 1: <br />
            your Future-readiness development Radar
          </Typography>
          <Typography className="s-caption">
            (your development goals based on your test choices)
          </Typography>

          <Typography className="part-one-content">
            The Future-Readiness Radar denotes the 6 dimensions that have been
            identified as critical to becoming Future-ready in Tata Steel.
          </Typography>
          <Typography className="part-one-content">
            Shown below is your Future-Readiness Development Radar, based on
            your choices in the test. It helps you identify your development
            goals on each of the 6 dimensions of future-readiness.
          </Typography>
          {children}
          <RadarFooter />
        </div>

        {app.userTestDetails.targetedReflections ? (
          <div>
            <div
              id="part2"
              style={{
                backgroundColor: "white",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                borderRadius: 15,
                maxWidth: "833px",
                minHeight: 500,
                margin: "20px auto 30px",
                padding: "65px 90px",
              }}
            >
              <Typography className="s-heading">
                part 2: <br /> targeted self-development
              </Typography>
              <Typography className="s-caption">
                (deeper diagnosis to help you approach <br /> your development
                goals)
              </Typography>
              <Typography className="mode-title">
                In the previous section of this report, you’ve seen your development goal for each of the 6 dimensions of the Future-ready Operating Ideal. This section gives you specific ways (or modes) to develop on these dimensions.
              </Typography>
              <div style={{ fontSize: 18, fontWeight: 500}}>Recommended Modes for Development</div>
              <p style={{margin:'1rem 0px'}}>In order to achieve your goals, there are three possible modes for development –</p>
              {modes.map((m, i) => {
                return (
                  <div key={`modes-${i}`} className="modes">
                    <div style={{fontWeight: 500}}>{i + 1}. {m.title}</div>
                    <div className="content">
                      <div className="dimensions-caption">{m.caption}</div>
                      {TRs.length && i === 1 ? <a className="nlinks small" href="#part2-B">
                        <PlayArrow className="play" />
                        <div style={{color:'#000'}}>
                          Refer Table 2 for reflection spaces & recommended practices.
                        </div>
                      </a> : null}
                    </div>
                  </div>
                );
              })}
  <br />
              <a className="nlinks small" href="#part2-A">
                <PlayArrow className="play" />
                <div>Refer Table 1 given below to know your recommended modes of development for each of the 6 dimensions.
                </div>
              </a>

            </div>

            <div
              id="part2-A"
              style={{
                backgroundColor: "white",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                borderRadius: 15,
                maxWidth: "833px",
                minHeight: 500,
                margin: "20px auto 30px",
                padding: "65px 90px",
              }}
            >
              <Typography className="s-heading" style={{ marginBottom: 15 }}>
                TABLE 1: YOUR RECOMMENDED MODE OF DEVELOPMENT
              </Typography>
              <div className="part2-table-wrapper">
                <div
                  style={{
                    display: "flex",
                    fontSize: ".875rem",
                    textTransform: "uppercase",
                    borderTop: "1px solid #CCCCCC",
                  }}
                >
                  <div style={{ width: 170, padding: 16, fontWeight: 500 }}>
                    Dimensions of Future-Ready Operating Ideal
                  </div>
                  <div
                    style={{
                      width: 260,
                      borderLeft: "1px solid #CCCCCC",
                      borderRight: "1px solid #CCCCCC",
                      padding: 16,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    Development Goal
                  </div>
                  <div
                    style={{
                      width: "calc(100% - 400px)",
                      padding: 16,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    Recommended mode of development
                  </div>
                </div>
                {Object.keys(app.userTestDetails.targetedReflections).map(
                  (el) => {
                    return (
                      <div
                        key={`m-${el}`}
                        style={{
                          display: "flex",
                          borderTop: "1px solid #CCCCCC",
                        }}
                      >
                        <div
                          style={{
                            width: 170,
                            padding: 16,
                            fontWeight: 500,
                            textTransform: "uppercase",
                            fontSize: "0.75rem",
                          }}
                        >
                          {dimensions[el]}
                        </div>
                        <div
                          style={{
                            width: 260,
                            borderLeft: "1px solid #CCCCCC",
                            borderRight: "1px solid #CCCCCC",
                            padding: 16,
                            fontWeight: 400,
                            fontSize: ".875rem",
                          }}
                        >
                          {app.userTestDetails.report[el].goal}
                        </div>
                        <div
                          style={{
                            width: "calc(100% - 400px)",
                            padding: 16,
                            fontSize: ".875rem",
                          }}
                        >
                          {reflectionQuestions(
                            app.userTestDetails.targetedReflections[el]
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>

              {TRs.length ? null : (
                <Typography style={{ margin: "30px 0", fontWeight: 400 }}>
                  Wishing you the best on your development journey. Use all the
                  resources and enablers available to become future ready.
                </Typography>
              )}
              <RadarFooter />
            </div>
          </div>
        ) : null}

        {TRs.length ? (
          <div
            id="part2-B"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              borderRadius: 15,
              maxWidth: "833px",
              minHeight: 500,
              margin: "20px auto 30px",
              padding: "65px 90px",
            }}
          >
            <Typography className="s-heading" style={{ marginBottom: 15 }}>
              TABLE 2: REFLECTION SPACES & RECOMMENDED PRACTICES
            </Typography>
            <Typography>
              In the following dimensions, you require just 1 or 2 shifts in your
              thinking patterns <br /> to reach your development goal.
            </Typography>
            {TRs.map((el, i) => {
              let D = app.userTestDetails.targetedReflections[el];
              const letters = ["A", "B", "C", "D", "E"];
              return (
                <React.Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "10px 0",
                      fontSize: ".875rem",
                    }}
                  >
                    Table 2{letters[i]}
                  </div>
                  <div key={el} className="t-wrapper">
                    <div
                      style={{ padding: "10px", backgroundColor: "#f7f7f7" }}
                    >
                      <div
                        style={{
                          textTransform: "uppercase",
                          marginBottom: 10,
                          color: "#000",
                          fontWeight: 500,
                        }}
                      >
                        {dimensions[el]}
                      </div>
                      <div>{app.userTestDetails.report[el].goal}</div>
                    </div>
                    <Grid
                      container
                      className="top-border"
                      style={{ textAlign: "center" }}
                    >
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        style={{
                          padding: "10px",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        Reflection Space
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        style={{
                          borderLeft: "1px solid #cccccc",
                          borderRight: "1px solid #cccccc",
                          padding: "10px",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        The Future-Ready thinking expected
                      </Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        sm={5}
                        style={{
                          padding: "10px",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Practices to be strengthened
                      </Grid>
                    </Grid>
                    {Object.keys(D.reflections).map((el) => {
                      let x = D.reflections[el];
                      return (
                        <Grid key={el} container className="top-border">
                          <Grid
                            item
                            lg={3}
                            md={3}
                            sm={3}
                            style={{ padding: "10px", fontWeight: 400, display: 'flex' }}
                          >
                            {x.text}
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            style={{ padding: "10px", fontWeight: 400, display: 'flex', borderLeft: "1px solid #cccccc",
                            borderRight: "1px solid #cccccc" }}
                          >
                            {/* <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                              <div style={{ display: 'flex' }}>
                                <div
                                  style={{
                                    padding: "10px",
                                    fontWeight: 500,
                                    borderBottom: "1px solid #cccccc",
                                    textAlign: 'center',
                                    width: '50%'
                                  }}
                                >
                                  Thinking reflected in your response
                                </div>
                                <div
                                  style={{
                                    borderLeft: "1px solid #CCCCCC",
                                    padding: "10px",
                                    fontWeight: 500,
                                    borderBottom: "1px solid #cccccc",
                                    textAlign: 'center',
                                    width: '50%'
                                  }}
                                >
                                  Future-Ready Thinking
                              </div>
                              </div>
                              <div style={{ display: 'flex', height:'100%' }}>
                                <div
                                  style={{ padding: "10px", fontWeight: 400, width: '50%' }}
                                >
                                  {x.current}
                                </div>
                                <div
                                  style={{
                                    borderLeft: "1px solid #CCCCCC",
                                    padding: "10px",
                                    fontWeight: 400, width: '50%'
                                  }}
                                >
                                  {x.goal}
                                </div>
                              </div>
                            </div> */}
                          {x.goal}
                          </Grid>
                          <Grid
                            item
                            lg={5}
                            md={5}
                            sm={5}
                            style={{ padding: "10px", fontWeight: 400 }}
                          >
                            <div>{x.practices ? x.practices.head : null}</div>
                            <ol style={{ marginLeft: "1rem" }}>
                              {(x.practices ? x.practices.text : []).map(
                                (p, i) => {
                                  return (
                                    <li
                                      key={`${el}-${i}`}
                                      style={{ margin: "8px 0" }}
                                    >
                                      {p}
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
            <Typography style={{ margin: "30px 0", fontWeight: 400 }}>
              Wishing you the best on your development journey. Use all the
              resources and enablers available to become future ready.
            </Typography>
            <RadarFooter />
          </div>
        ) : null}

        <div
          style={{
            backgroundColor: "white",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
            borderRadius: 15,
            maxWidth: "833px",
            margin: "30px auto",
            padding: "65px 90px",
          }}
        >
          <Typography className="c-title">
            about the cognitive assessment
          </Typography>
          <Typography className="c-content">
            The Future-Readiness Cognitive Test identifies the deeper patterns
            of thought for each dimension of the Future-ready Operating Ideal,
            using Illumine’s proprietary knowledge models and cognitive modeling
            methodologies. <br />
            <br /> These have been successfully leveraged in large scale
            interventions covering over 4 million people till date.
          </Typography>
        </div>

        <div style={{ textAlign: "center", marginBottom: "150px" }}>
          <Button
            component={Link}
            to="/dashboard"
            color="primary"
            variant="contained"
            size="large"
            style={{ height: 50, width: "auto" }}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportLayout;
