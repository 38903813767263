import React from "react";
import SupportComponent from "../SupportComponent";

import data from "./data";

const ConnectWith = () => {
  const caption =
    "For any kind of support, Culture Champs can connect with Culture Lab team. In addition to Culture Champs, others too are welcome to connect with Culture Lab, when needed.";

  React.useEffect(() => {
    document.getElementById("header").scrollIntoView(true);
  }, []);

  return (
    <div className="connect-with-wrapper">
      <SupportComponent
        data={data}
        caption={caption}
        lHead="Culture Champs"
        rHead="Culture Lab - SPOC"
      />
    </div>
  );
};

export default ConnectWith;
