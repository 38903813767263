import React from "react";
import { Typography, Button } from "@material-ui/core";

import "./styles.scss";

const ReviewCheck = (props) => {
  const { reviewTest, continueTest, currentSectionQuestions } = props;
  return (
    <div className="review-check-wrapper">
      <Typography className="title">
        Well done! You have completed a set of 36 questions.
        <br />
        Once you Confirm, your responses for this set will be considered for
        your results and you will be able to proceed to the next set of
        questions.
      </Typography>
      <div>
        <Button
          variant="contained"
          size="large"
          className="btns"
          color="primary"
          onClick={continueTest}
        >
          Confirm and continue
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          size="large"
          className="btns"
          onClick={reviewTest}
        >
          Go back to review
        </Button>
      </div>
    </div>
  );
};

export default ReviewCheck;
