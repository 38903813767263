import React, { useState } from "react";
import { Grid, Tooltip, Typography, Button, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import _ from "lodash";

import "./styles.scss";

import Modal from "../Modal";
import VideoPlayer from "../VideoPlayer";
import clsx from "clsx";
import { useSelector } from "react-redux";
import {
  AccessTime,
  PlayCircleOutline,
  LockOutlined,
} from "@material-ui/icons";
import { ReactSVG } from "react-svg";

import WatchVideo from "../WatchVideo";

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);
  const exploreTooltip =
    "This Zone will unlock after you complete the test in Zone 1";

  const videos = app.settings.Home;

  const handleClose = () => {
    setOpen(!open);
  };

  const handleVideoPlayer = () => {
    setOpen(true);
  };

  const isTestAssessmentDone = () => {
    return app.isTestCompleted;
  };

  const handleShowTooltip = () => {
    setShowTooltip(!isTestAssessmentDone());
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  return (
    <React.Fragment>
      <div className="dashboard-container">
        <div className="content">
          <div className="head-title">Welcome {auth.user_fullname}!</div>
          <Grid container spacing={2}>
            <Grid md={6} lg={7} sm={12} item>
              <div
                className="video-container"
                onClick={handleVideoPlayer}
                style={{
                  background: 'url("/assets/home-vp-bg.svg") no-repeat',
                }}
              >
                <div className="video-title-wrapper">
                  <Grid container spacing={3}>
                    <Grid item sm={6} md={12} lg={6}>
                      <div className="vp-wrapper">
                        <img src="/assets/VP-video-cover.png" alt="vp-video" />

                        <PlayCircleOutline className="play-white" />
                      </div>
                    </Grid>
                    <Grid item sm={6} md={12} lg={6}>
                      <Typography className="vt-title">
                        Embark on your journey to becoming future-ready
                      </Typography>
                      <Typography className="video-caption">
                        SURESH DUTT TRIPATHI, <br /> Vice President - Human
                        Resource Management
                      </Typography>
                    </Grid>
                  </Grid>
                </div>

                {/* <Hidden mdDown> */}
                <div className="coming-soon">
                  {/* <PlayCircleOutline /> */}
                  <AccessTime />
                  Coming Soon
                </div>
                {/* </Hidden> */}
              </div>
            </Grid>
            <Grid item md={6} lg={5} sm={12}>
              <Grid container spacing={2}>
                <Grid md={12} lg={12} sm={12} item>
                  <Link className="tiles map" to="/map">
                    <span className="number">1</span> Map your <br />{" "}
                    Future-Readiness
                    <img src="/assets/map.png" alt="map" />
                  </Link>
                </Grid>
                <Grid md={12} lg={12} sm={12} item>
                  <Tooltip
                    title={exploreTooltip}
                    open={showTooltip}
                    onClose={handleTooltipClose}
                    onOpen={handleShowTooltip}
                  >
                    <Link
                      className={clsx("tiles explore", {
                        locked: !isTestAssessmentDone(),
                      })}
                      to={!isTestAssessmentDone() ? "#" : "/explore"}
                    >
                      <span className="number">2</span>
                      <div>
                        Explore the dimensions <br /> of Future-Readiness
                        {!isTestAssessmentDone() && (
                          <div className="coming-soon">
                            <LockOutlined /> Locked
                          </div>
                        )}
                      </div>
                      <img src="/assets/bulb.png" alt="map" />
                    </Link>
                  </Tooltip>
                </Grid>
                <Grid md={12} lg={12} sm={12} item>
                  <Link
                    className={clsx("tiles develop", {
                      locked: !isTestAssessmentDone(),
                    })}
                    to={!isTestAssessmentDone() ? "#" : "/develop"}
                  >
                    <span className="number">3</span>
                    <div>
                      Develop <br /> yourself
                      {!isTestAssessmentDone() && (
                        <div className="coming-soon">
                          <LockOutlined /> Locked
                        </div>
                      )}
                    </div>
                    <img src="/assets/zone3-icon_develop.svg" alt="map" />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="support-grid-wrapper">
          <Typography className="text">
            Support for your journey to Future-Readiness:
          </Typography>
          <div className="support-grid">
            <ReactSVG
              className="s-icon"
              src="/assets/noun_support_1256002.svg"
            />
            <Link className="s-links champs" to="know-your-culture-champs">
              Know your Culture Champs
            </Link>
            <Link to="/culture-lab-team" className="s-links">
              Connect with the Culture Lab Team
            </Link>
          </div>
        </div>
      </div>
      {videos && videos.VPVideo !== "video url" && (
        <Modal open={open} handleClose={handleClose}>
          <VideoPlayer
            mediaUrl={videos.VPVideo}
            posterUrl={videos.VPVideoCover}
          />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
