import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

// import Auth from "../auth";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import Dashboard from "../../components/Dashboard";
import DevelopYourSelf from "../../components/DevelopYourSelf";
import Explore from "../../components/Explore";
import MapFuture from "../../components/MapFuture";
import TestAssessment from "../../components/TestAssessment";
import TestReport from "../../components/TestReport";
import Walkthrough from "../../components/Walkthrough";
import Leadership from "../../components/Leadership";
import CaseExamples from "../../components/CaseExamples";
import SelfCheck from "../../components/SelfCheck";
import StartAssessment from "../../components/TestAssessmentStart";
import AuthLayout from "../_layouts/auth";
import AssessmentLayout from "../_layouts/assessment";
import ReportLayout from "../_layouts/report";
import NoLayout from "../_layouts/nolayout";
import TechSupport from "../../components/TechSupport";
import KnowYourChamps from "../../components/KnowYourChamps";
import ReachOutToLabTeam from "../../components/ConnectWith";

import "../../containers/app/redux_actions";

const Routes = () => {
  return (
    <Switch>
      <Route path="/auth/login" component={Login} layout={AuthLayout} />
      <Route path="/auth/register" component={Register} layout={AuthLayout} />
      <Route path="/auth/reset-password" component={ForgotPassword} />
      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/develop" component={DevelopYourSelf} isPrivate />
      <Route exact path="/explore" component={Explore} isPrivate />
      <Route exact path="/map" component={MapFuture} isPrivate />
      <Route
        exact
        path="/assessment"
        component={TestAssessment}
        layout={AssessmentLayout}
        isPrivate
      />
      <Route
        exact
        path="/report"
        component={TestReport}
        layout={ReportLayout}
        isPrivate
      />
      <Route
        exact
        path="/explore/:dimension/walkthrough"
        component={Walkthrough}
        data={{ showHeader: false }}
        isPrivate
      />
      <Route
        exact
        path="/explore/:dimension/leadership"
        component={Leadership}
        isPrivate
      />
      <Route
        exact
        path="/explore/:dimension/case-examples"
        component={CaseExamples}
        isPrivate
      />
      <Route
        exact
        path="/explore/:dimension/selfreflect"
        component={SelfCheck}
        isPrivate
      />
      <Route
        exact
        path="/start-assessment"
        component={StartAssessment}
        layout={NoLayout}
        isPrivate
      />
      <Route
        exact
        path="/tech-support"
        component={TechSupport}
        layout={NoLayout}
        isPrivate
      />
      <Route
        exact
        path="/know-your-culture-champs"
        component={KnowYourChamps}
        isPrivate
      />
      <Route
        exact
        path="/culture-lab-team"
        component={ReachOutToLabTeam}
        isPrivate
      />
      <Route path="/" component={Login} layout={AuthLayout} />
    </Switch>
  );
};

export default Routes;
