import React, { useState, useEffect } from "react";
import "./style.css";
let ImageSlide = ({ next, previous, imgUrl, classes, dimensions }) => {
  // let providedRatio = dimensions.slideWidth/dimensions.slideHeight;
  return (
    <div
      className={`img-slide${
        classes && classes.slide ? ` ${classes.slide}` : ""
      }`}
    >
      <div style={{ maxHeight: "100%" }}>
        <img
          src={imgUrl}
          alt="case-examples"
          style={{
            maxWidth: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default ImageSlide;
