import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#FFBC36",
    },
    secondary: {
      main: "#FF9761",
    },
  },
  typography: {
    fontFamily: '"Rubik", sans-serif',
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,0,.96)",
      },
    },
    MuiDialog: {
      container: {
        // height: "auto",
        // position: "absolute",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
      },
      root: {
        padding: "0",

        "&:first-child": {
          padding: 0,
        },
      },
      // paperFullWidth: {
      //   width: "calc(100% - 376px)",
      //   borderRadius: "10px",
      // },
      // paperScrollPaper: {
      //   maxHeight: "calc(100% - 123px)",
      // },
    },
    MuiStepper: {
      root: {
        padding: "44px 24px 34px 0",
        borderRadius: "5px",
      },
    },
    MuiStepIcon: {
      active: {
        color: "transparent !important",
        width: "1.5em",
        height: "1.5em",
        border: "3px solid #FF9761 !important",
        borderRadius: "50%",
      },
    },
    MuiStepConnector: {
      vertical: {
        padding: "0",
        margin: "5px 19px",
        marginLeft: "19px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "25px",
      },
      contained: {
        backgroundColor: "#FFFFFF",
      },
      containedPrimary: {
        borderRadius: "25px",
      },
      containedSizeLarge: {
        padding: "8px 42px",
        fontSize: ".8rem",
      },
    },
    MuiFormControl: {
      root: {
        margin: "8px 0",
      },
    },
  },
});
