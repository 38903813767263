export default [
  {
    d: "HRM TSK",
    cc: "Adrit Paul",
  },
  {
    d: "HRM Strategy<br/>Office of Vice-President(HRM)",
    cc: "Amrita Bharadwaj",
  },
  {
    d: "HRM Profit Centres & Corporate Functions",
    cc: "Annesha Pramanik<br/>Kanu Agrawal<br/>Karan Lakhani",
  },
  {
    d:
      "HRM - Engineering & Projects<br/>HRM - Projects TSK & TGS<br/>Amenities & CWR Cell - K&E and PC",
    cc: "Anuj Dutta",
  },
  {
    d: "Amenities & CWR Cell<br/>HRM - Flat Products<br/>HRM - Long Products",
    cc: "Deeba Ahmad",
  },
  {
    d: "HRM - FAMD",
    cc: "Deepak Varghese",
  },
  {
    d:
      "ER & Services Pool - Steel<br/>Govt. Liaison & Statutory Compliance<br/>HRM Steel - M&S<br/>Office of Chief HRM - JSR Steel<br/>Office of Chief HRM - Steel<br/>Talent Management - Steel",
    cc: "Devi Prasad Panthala",
  },
  {
    d: "HRM Services",
    cc: "Jaya Lekshmy Venugopal",
  },
  {
    d: "HRM-Knowledge & Enabling Functions",
    cc: "Kanu Agrawal<br/>Manish Kumar",
  },
  {
    d: "HRM - Iron Making",
    cc: "Karan Lakhani",
  },
  {
    d: "Group HR",
    cc: "Neena Bahadur",
  },
  {
    d: "Capability Development",
    cc:
      "Pankaj Kumar<br/>Pradeep Gnana Nerayanuri<br/>Preetha Simlai Saha<br/>Arvind Vishwanath Raikar<br/>Shiwangi Sinha",
  },
  {
    d: "Group IR",
    cc: "Rajesh Kumar Jha",
  },
  {
    d: "Productivity Services Department",
    cc: "Sushovan Ghosh<br/>S. Sundarameenakshi<br/>Sharath Kumar",
  },
  {
    d: "Employee Relations - RM<br/>HRM - Jharia",
    cc: "Shiv Shanker",
  },
  {
    d: "HRM - West Bokaro",
    cc: "Shreya Ganguly",
  },
  {
    d: "HRM - Shared Services",
    cc: "Sonam Ranjan",
  },
  {
    d:
      "HRM - NRD, Global Minerals, RMSG &Others<br/>HRM - OM&Q<br/>Office of Chief HRM - RM<br/>Talent Management - RM",
    cc: "Sudhir Kumar Singh",
  },
];
