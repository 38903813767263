import React, { Component } from 'react';
import { connect } from "react-redux";
import MultiPageTemplate from '../MultiPagePDFReader';

import './glide_core.css';
import './glide_theme.css';
import './book.css';
import './book-multipage.css';

// const isBrowser = () => typeof window !== 'undefined' && window.document !== undefined;
const duringLoad = <div className='loading-screen'>Loading...</div>

let Book = class Book extends Component {
  render() {
    let unit = this.props.unit
    if (unit) { return serveTemplate(unit) }
    return <div>No Such Book exists</div>
  }
}

function serveTemplate(unit) {
  let src = unit.mediaUrl, docRatio = unit.docRatio || 1.4, dimensions = unit.dimensions||{};
  if(unit.type == 'pdf') {
    return <div id='_book_meta_cntr' className='_book_meta_cntr'>
      <MultiPageTemplate src={src} duringLoad={duringLoad} docRatio={docRatio} dimensions={unit.dimensions}/>
    </div>
  } else 
    return null;
}
const mapStateToProps = state => ({ app: state.app });
export default connect(mapStateToProps, null)(Book);