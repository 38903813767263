import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import T from "prop-types";

import "./styles.scss";

const Support = (props) => {
  const { data, title, caption, lHead, rHead } = props;
  return (
    <div className="support-component-wrapper">
      {/* <Typography className="title">{title}</Typography> */}
      <Typography className="caption">{caption}</Typography>
      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell>{lHead}</TableCell>
            <TableCell>{rHead}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((d, i) => (
              <TableRow>
                <TableCell dangerouslySetInnerHTML={{ __html: d.c || d.d }} />
                <TableCell
                  dangerouslySetInnerHTML={{ __html: d.spoc || d.cc }}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

Support.propTypes = {
  data: T.array,
  title: T.string,
  caption: T.string,
};

export default Support;
