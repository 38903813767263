/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Menu,
  MenuItem,
  Typography,
  Popover,
  List,
  ListItem,
} from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import { signOut } from "../../../containers/auth/redux_actions";
import _ from "lodash";

import "./styles.scss";

import AvatarWithUsername from "../../../components/AvatarWithUsername";

import { ReactComponent as ProfileIcon } from "./profile_icon.svg";
import { ReactComponent as HomeIcon } from "./home-icon.svg";
import { ReactComponent as MapIcon } from "./zone1-icon-map.svg";
import { ReactComponent as ExploreIcon } from "./zone2-icon-explore.svg";
import { ReactComponent as DevelopIcon } from "./zone3-icon_develop.svg";
import { ReactComponent as SupportIcon } from "./noun_support_1256002.svg";

import { ExpandMore, ArrowBackIos } from "@material-ui/icons";
import Footer from "../../../components/Footer";

const animate =
  '<animate attributeType="SVG" attributeName="r" begin="0s" dur="1.5s" repeatCount="indefinite" from="0%" to="5%"></animate><animate attributeType="CSS" attributeName="stroke-width" begin="0s" dur="1.5s" repeatCount="indefinite" from="1%" to="0%"></animate><animate attributeType="CSS" attributeName="opacity" begin="0s" dur="1.5s" repeatCount="indefinite" from="1" to="0"></animate>';

const DefaultLayout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorProfileEl, setAnchorProfileEl] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const [activeUrl, setActiveUrl] = useState(null);
  const [ActiveIcon, setActiveIcon] = useState(null);

  const { pathname } = useLocation();
  const history = useHistory();

  const paths = pathname.split("/");
  const firstPath = paths[1];
  const activePath = paths[2];
  const pCircles = document.querySelectorAll("circle[stroke-width='0.5']");

  for (const pulse of pCircles) {
    pulse.innerHTML += animate;
    pulse.addEventListener("click", function (e) {
      alert("Pulse circle click");
    });
  }

  const navigation = [
    {
      label: "Access your future <br/> readiness index",
      link: "/map",
    },
    {
      label: "Explore your future <br/> readiness index",
      link: "/explore",
    },
    {
      label: "Develop <br/> Yourself",
      link: "/develop",
    },
    {
      label: "Mentor <br/> Zone",
      link: "/develop",
    },
  ];

  const zones = {
    explore: {
      zone: "Zone 2",
      title: "Explore dimensions of future-readiness",
      color: "#FFB039",
    },
    map: {
      zone: "Zone 1",
      title: "Map your Future-Readiness",
      color: "#ff9761",
    },
    develop: {
      zone: "Zone 3",
      title: "Develop Yourself",
      color: "",
    },
    "know-your-culture-champs": {
      zone: "Support",
      title: "Know Your Culture Champs",
      color: "#9e9e9e",
    },
    "culture-lab-team": {
      zone: "Support",
      title: "Connect with the Culture Lab Team",
      color: "##9e9e9e",
    },
  };
  const dimensions = [
    {
      label: "Ownership",
      value: "ownership",
      link: `/explore/ownership/walkthrough`,
    },
    {
      label: "Growing in Aspirations",
      value: "growing",
      link: `/explore/growing/walkthrough`,
    },
    {
      label: "Responsiveness",
      value: "responsiveness",
      link: `/explore/responsiveness/walkthrough`,
    },
    {
      label: "Collaboration",
      value: "collaboration",
      link: `/explore/collaboration/walkthrough`,
    },
    {
      label: "Becoming a Value Creator",
      value: "valuecreator",
      link: `/explore/valuecreator/walkthrough`,
    },
    {
      label: "Evolving Professionally",
      value: "evolving",
      link: `/explore/evolving/walkthrough`,
    },
  ];

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setAnchorProfileEl(null);
  };

  const handleMenuItem = (e, d) => {
    const { label } = d;
    setMenuItem(label);
    // history.push(label.link);
    handleDropdownClose();
  };

  const handleBack = () => {
    history.goBack(-1);
  };

  const handleProfileMenu = (event) => {
    setAnchorProfileEl(event.currentTarget);
  };

  useEffect(() => {
    if (firstPath === "explore" && paths.length > 2) {
      let f = _.find(dimensions, { value: activePath });
      const u = _.find(navigation, { link: `/${firstPath}` });

      if (!f) {
        f = _.find(dimensions, { value: "walkthrough" });
      }
      setActiveUrl(u.label);
      setMenuItem(f.label);
    }
    let scrollable = document.getElementById("layout-wrapper");
  }, [activePath]);

  const getActiveIcon = () => {
    switch (firstPath) {
      case "explore":
        return <ExploreIcon />;
      case "map":
        return <MapIcon />;
      case "develop":
        return <DevelopIcon />;
      default:
        return <SupportIcon />;
    }
  };
  return (
    <div className="screen-cntr">
      <div id="layout-wrapper" className="layout-wrapper">
        <div className="layout-container">
          <header id="header" className="header">
            {paths.length > 2 && (
              <div className="dimension-header">
                <div className="zone-header-content">
                  <Link className="back-link" to="/explore" underline="none">
                    <div className="back-icon">
                      <ArrowBackIos />
                    </div>
                    <div className="zone-icon">
                      <img
                        src="/assets/header-radar-dimension-new.svg"
                        alt="header-logo"
                      />
                    </div>
                  </Link>
                  <div className="zone-name">
                    <Typography className="zone-num">
                      future-readiness dimension
                    </Typography>
                    <div className="zone-title">
                      <div
                        className="dropdown-menu"
                        onClick={handleDropdownClick}
                      >
                        {menuItem} <ExpandMore />
                      </div>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleDropdownClose}
                        className="nav-inner-menu"
                      >
                        {dimensions.map((d, i) => {
                          return (
                            <MenuItem
                              key={"menu" + i}
                              onClick={(e) => handleMenuItem(e, d)}
                            >
                              <Link to={d.link}> {d.label}</Link>
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(firstPath === "map" ||
              firstPath === "explore" ||
              firstPath === "culture-lab-team" ||
              firstPath === "know-your-culture-champs") &&
              paths.length <= 2 && (
                <div className="zone-header">
                  <Link className="home-icon" to="/dashboard">
                    <HomeIcon />
                  </Link>

                  <div className="zone-header-content">
                    <div className="back-icon">
                      <ArrowBackIos />
                    </div>
                    <div
                      className="zone-icon"
                      style={{ fill: zones[firstPath]?.color }}
                    >
                      {firstPath && getActiveIcon()}
                    </div>
                    <div className="zone-name">
                      <Typography className="zone-num">
                        {firstPath && zones[firstPath]?.zone}
                      </Typography>
                      <Typography className="zone-title">
                        {firstPath && zones[firstPath]?.title}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            {firstPath === "dashboard" && (
              <div className="header-left">
                <div className="header-radar-icon">
                  <img src="/assets/header-radar.svg" alt="header-logo" />
                </div>
                <Link
                  className="title-wrapper"
                  to="/dashboard"
                  underline="none"
                >
                  <div className="logo-title">Tata Steel</div>
                  <div className="title">Future-Ready Explorium</div>
                </Link>
              </div>
            )}
            {firstPath==="develop"&&(
              <div className="zone-header">
              <Link className="home-icon" to="/dashboard">
                <HomeIcon />
              </Link>

              <div className="zone-header-content">
                <div className="back-icon">
                  <ArrowBackIos />
                </div>
                <div
                  className="zone-icon"
                  style={{ fill: zones[firstPath]?.color }}
                >
                  {firstPath && getActiveIcon()}
                </div>
                <div className="zone-name">
                  <Typography className="zone-num">
                    {firstPath && zones[firstPath]?.zone}
                  </Typography>
                  <Typography className="zone-title">
                    {firstPath && zones[firstPath]?.title}
                  </Typography>
                </div>
              </div>
            </div>
            )}
            <div className="navigation-wrapper">
              A Tata Steel Culture Lab + Illumine Collaboration
              {/* <div className="logo-wrapper">
                <img
                  src="/assets/header-illumine-logo.svg"
                  alt="illumine_logo"
                />
              </div> */}
            </div>
            <div className="profile-dropdown" onClick={handleProfileMenu}>
              <div className="profile-wrapper">
                <AvatarWithUsername />
                <ExpandMore />
              </div>
            </div>
            <Popover
              open={Boolean(anchorProfileEl)}
              anchorEl={anchorProfileEl}
              onClose={handleDropdownClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List className="p-menu">
                <ListItem className="p-menu-list-item">
                  <div
                    onClick={() => {
                      signOut();
                    }}
                  >
                    Logout
                  </div>
                </ListItem>
              </List>
            </Popover>
          </header>
          <div className="content-wrapper">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
