import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { NavLink, useParams } from "react-router-dom";
import clsx from "clsx";

import "./styles.scss";
import { useSelector } from "react-redux";

const EvolveLayout = (props) => {
  let { children } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const { dimension } = useParams();
  const app = useSelector((state) => state.app);

  const report = (app.userTestDetails && app.userTestDetails.report) || {};

  const links = [
    {
      title: "walk-through",
      icon: "/assets/walkthrough.svg",
      link: `/explore/${dimension}/walkthrough`,
    },
    {
      title: "leader speak",
      icon: "/assets/eye.svg",
      link: `/explore/${dimension}/leadership`,
    },
    {
      title: "case examples",
      icon: "/assets/case.svg",
      link: `/explore/${dimension}/case-examples`,
    },
    {
      title: "self Reflect",
      icon: "/assets/self-reflect-icon.svg",
      link: `/explore/${dimension}/selfreflect`,
    },
  ];

  const goals = {
    collaboration: "d001",
    ownership: "d002",
    growing: "d005",
    responsiveness: "d006",
    valuecreator: "d004",
    evolving: "d003",
  };

  const handleActive = (e, i) => {
    setActiveIndex(i);
  };

  const dim = goals[dimension];
  const goalLevel = report[dim];

  const getLevel = (level) => {
    switch (level) {
      case "lvl1":
        return "Level 1";
      case "lvl2":
        return "Level 2";
      case "lvl3":
        return "Level 3";
	 case "lvl4":
        return "Continue on Level 3";
      default:
        break;
    }
  };

  return (
    <div className="evolve-wrapper height-100">
      <div className="dim-goal">
        Your development goal: <span className="level-indicator"></span>
        <Typography className="normal-text">
          {getLevel(goalLevel.lvl)}
        </Typography>
      </div>
      <Grid container className="height-100 reverse-order">
        <Grid item lg={10} md={12} sm={12} className="height-100">
          <div className="evolve-layout height-100">{children}</div>
        </Grid>
        <Grid item lg={2} md={12} sm={12}>
          <div className="evolve-links-wrapper">
            <Typography className="regular-text">
              Explore this dimension In 4 ways
            </Typography>
            <ul className="links-list">
              {links.map((l, i) => {
                return (
                  <li
                    key={i}
                    className={clsx("list-item", { active: i === activeIndex })}
                    onClick={(e) => handleActive(e, i)}
                  >
                    <NavLink className="list-item-a" to={l.link}>
                      <div className="icon-wrapper">
                        <img src={l.icon} alt={l.title} />
                      </div>
                      {l.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default EvolveLayout;
