import React, {useEffect} from "react";
import { Grid, Typography } from "@material-ui/core";
import { updateLastVist } from '../../containers/app/redux_actions';
import "./styles.scss";
import { useSelector } from "react-redux";
import Radar from "./radar";

const TestReport = () => {
  const { user_fullname } = useSelector((state) => state.auth);
  const a = useSelector((state) => state.auth);    
    useEffect(()=>{
      updateLastVist({ uid:a.user.uid, testId:'fyidom' })
    },[])
    
  const { report } = useSelector((state) => state.app.userTestDetails) || {};
  const { config } = useSelector((state) => state.app.userTestDetails);
  let d = new Date(config.startedOn);
  const dimensions = [
    { label: "Collaboration" },
    { label: "Ownership" },
    { label: "Evolving Professionally" },
    { label: "Becoming a Value Creator" },
    { label: "Growing in Aspirations" },
    { label: "Responsiveness" },
  ];
  return (
    <div>
      <div className="test-report-wrapper">
        <div className="radar">
          <div style={{ fontSize: "0.9375rem" }}>
            <Typography className="section-heading">
              Your Future-Readiness Development Radar
            </Typography>
          </div>
          <Grid container className="section">
            <Grid item lg={12} md={12} sm={12} style={{ textAlign: "center" }}>
              <Radar data={report} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} className="top-border">
              <Grid container className="radar-footer">
                <Grid item lg={4} md={4} sm={4}>
                  <div>
                    <img src="/assets/legend.svg" alt="legend" />
                  </div>
                </Grid>
                <Grid item lg={8} md={8} sm={8}>
                  <Typography className="future-description">
                    Every dimension has 3 levels represented by each concentric
                    circle. The innermost circle being Level 1 - indicating that
                    you have just started your journey & the outermost circle
                    being Level 3 - indicating that you are almost on the last
                    leg of your development journey. <br /> <br /> The green dot
                    represents your development goal i.e. the level of practice
                    you need to work towards, in order to move to the next
                    level.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="section" style={{ margin: "60px 0px 0px" }}>
          <Typography className="head-title"></Typography>
          <Typography className="section-heading">
            Interpreting your Future-readiness Development Radar:
          </Typography>
        </div>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} className="d-row-head">
            <Grid container>
              <Grid item ld={4} md={4} sm={4}>
                Dimensions
              </Grid>
              <Grid item ld={8} md={4} sm={8}>
                Your Development goal
              </Grid>
            </Grid>
          </Grid>
          {Object.keys(report || {}).map((d, i) => {
            return (
              <Grid
                key={`mdtr-${i}-${d}`}
                item
                lg={12}
                md={12}
                sm={12}
                className="top-border d-row"
              >
                <Grid key={`mditr-${i}`} container>
                  <Grid item md={4} sm={4} className="d-wrapper">
                    <div>
                      <Typography className="dimensions-heading">
                        {dimensions[i].label}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={8} sm={8} className="d-wrapper">
                    <Typography className="dimensions-caption">
                      {report[d].goal}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default TestReport;
