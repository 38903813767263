import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { Component } from "react";
import Main from "./Main";
import { Provider, useSelector } from "react-redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";

import "./App.scss";

import theme from "./theme";
import store from "./Redux_Store.js";
import Routes from "./containers/routes";

var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

class App extends Component {
  render() {
    let persistor = persistStore(store);
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate
            loading={
              <div>
                <p>Loading</p>
              </div>
            }
            onBeforeLift={() => {}}
            persistor={persistor}
          >
            <Router>
              {/* <Main /> */}
              <Routes />
            </Router>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
