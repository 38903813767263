import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import EvolveLayout from "../Evolve";
import Modal from "../Modal";
import VideoPlayer from "../VideoPlayer";

import "./styles.scss";
import { useParams } from "react-router-dom";
import { PlayCircleOutline } from "@material-ui/icons";
import {updateUserEngagement} from "../../containers/app/redux_actions";

const leaderShipTitles = {
  ownership: "Why Ownership matters",
  growing: "Why it's important to grow in our aspirations",
  responsiveness: "Why Responsiveness matters",
  collaboration: "Why Collaboration matters",
  valuecreator: "Why creating value matters",
  evolving: "Why it's important to evolve professionally",
};

const LeaderShip = () => {
  const [open, setOpen] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { dimension } = useParams();
  const [leadership, setLeaderShip] = useState(null);
  const [leaderSpeakMedia, setLeaderSpeakMedia] = useState(null);
  const app = useSelector((state) => state.app);
  const dimensions = app.dimensions;
  const {user_uid} = useSelector((state) => state.auth);
  const handleVideoPlayer = () => {
    setOpen(!open);
  };

  const openVideoPlayer = (url, thumbnailUrl) => {
    setMediaUrl(url);
    setImageUrl(thumbnailUrl);
    setOpen(true);
  };

  useEffect(() => {
    //const _dimension = sectionData[dimension];
    const presentDimension = _.find(dimensions, (d) => {
      return d.id === dimension;
    });

    if (presentDimension) {
      const leaderSpeak = presentDimension.leaderSpeak;
      setLeaderShip(presentDimension);
      setLeaderSpeakMedia(leaderSpeak);
      setOpen(false);
    }
  }, []);

  return (
    leadership && (
      <EvolveLayout>
        <div className="leadership-wrapper">
          <Typography className="title">
            Leader Speak: {leaderShipTitles[dimension]}
          </Typography>
          <div className="grids-wrapper">
            <Grid container spacing={4}>
              {Object.keys(leaderSpeakMedia).map((el) => {
                let u = leaderSpeakMedia[el];
                return (
                  <Grid key={el} lg={6} md={6} sm={12} item>
                    <div
                      className="levels"
                      onClick={() => {
                        updateUserEngagement({uid: user_uid, dim:dimension, type:"leadership", unit: el});
                        openVideoPlayer(u.url, u.Cover)
                      }}
                    >
                      {/* <LazyLoadImage
                          alt={"image1"}
                          effect="blur"
                          src={u.poster}
                        /> */}
                      <img src={u.Cover} alt="image1" />
                      <PlayCircleOutline className="play-btn" />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        <Modal open={open} handleClose={handleVideoPlayer}>
          <VideoPlayer mediaUrl={mediaUrl} imgUrl={imageUrl} />
        </Modal>
      </EvolveLayout>
    )
  );
};

export default LeaderShip;
