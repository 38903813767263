import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import store from "../../Redux_Store";

const config = {
  apiKey: "AIzaSyC8milMT7C19VzlGkejXNkinyKfYAkuir8",
  authDomain: "tatasteel-futurebuilder-board.firebaseapp.com",
  databaseURL: "https://tatasteel-futurebuilder-board.firebaseio.com",
  projectId: "tatasteel-futurebuilder-board",
  storageBucket: "tatasteel-futurebuilder-board.appspot.com",
  messagingSenderId: "491058618302",
  appId: "1:491058618302:web:4911150ddde39ac4fce3ef",
  measurementId: "G-EYCE0H41KJ",
};

firebase.initializeApp(config);
let db = firebase.database();
let analytics = firebase.analytics();

db.ref('/settings').on('value', snapshot=>{
  store.dispatch({ type: 'UPDATE_SETTINGS', settings: snapshot.val()})
});

firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    let idToken = await user.getIdToken(/* forceRefresh */ true);
    
    let uid = user.uid;
    db.ref(`/users/${uid}`).on('value', (userSnapshot) => {
      let userData = userSnapshot.val();
      store.dispatch({ type: "SYNC_LOCAL_FROM_GLOBAL", payload: { ...userData } });
    }, error => {
      console.error(error);
    });
    loginAttempSuccess(store.dispatch, { user: { uid }, tokens: { refresh: { token: user.refreshToken }, idToken: idToken } }, () => { });
  } else {
    store.dispatch({ type: "LOGOUT_ATTEMPT" });
  }
});

export const loginAttempt = ({
  email,
  password,
  successCallBack,
  failureCallback,
}) => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_ATTEMPT", payload: true });
    if (password === "" && email !== "") {
      let errMsg = {};
      errMsg.message = "Please enter a valid password";
      loginAttempFail(dispatch, errMsg, failureCallback);
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          loginAttempSuccess(dispatch, user, successCallBack);
        })
        .catch((err) => {
          console.log(err);
          let errMsg = getErrorMsg(err);
          loginAttempFail(dispatch, errMsg, failureCallback);
          setTimeout(() => {
            dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: " " });
          }, 3000);
        });
    }
  };
};

export const registerAttempt = ({
  email,
  password,
  cPassword,
  fullname,
  accesscode,
  successCallBack,
  failureCallback,
}) => {
  return (dispatch) => {
    if (password === cPassword) {
      //Check AccessCode Here
      db.ref(`/accessCodes/${accesscode}/isValid`).on('value', (accessCodeDetails) => {
        console.log(accessCodeDetails.val());
        if (accessCodeDetails.val()) {
          dispatch({ type: "REGISTER_ATTEMPT", payload: true });
          firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((user) => {
              let updates = {};
              let uid = user.user.uid;
              updates[`/users/${uid}/basicProfile/name`] = fullname || 'Anon';
              updates[`/users/${uid}/basicProfile/email`] = email || null;
              updates[`/users/${uid}/accesscodes/${accesscode}`] = Date.now();
              db.ref().update(updates, (msg) => { console.log(msg) });
              // loginAttempSuccess(dispatch, user, successCallBack);
            })
            .catch((err) => {
              let errMsg = getErrorMsg(err);
              console.log(errMsg);
              loginAttempFail(dispatch, errMsg, failureCallback);
            });
        } else {
          loginAttempFail(dispatch, { message: 'Access Denied' }, failureCallback);
        }
      }, error => {
        console.error(error);
      });

    } else {
      let err =
        loginAttempFail(dispatch, { message: 'Confirm Password Mismatch' }, failureCallback);
    }
  };
};

export const setErrMsg = (el) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: el });
  };
};

export const resetPassword = (email) => {
  var auth = firebase.auth();
  var emailAddress = email;
  return (dispatch) => {
    if (email) {
      dispatch({ type: "PASSWORD_RESET_IN_PROGRESS", payload: true });
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function () {
          dispatch({ type: "RESET_PASSWORD_LINK_SENT", payload: true });
        })
        .catch(function (error) {
          let err = getErrorMsg(error);
          setErrorMsgInForgotPassword(dispatch, err.message);
        });
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid email id");
    }
  };
};

export const updateUserDetails = (user) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_UID", payload: user.uid });
  };
};

export const resetLoginPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_LOGIN_PAGE" });
  };
};

export const resetForgotPasswordPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_FORGOT_PASSWORD_PAGE" });
  };
};

export const registerNewUserPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_REGISTER_NEW_USER_PAGE" });
  };
};

export const signOut = () => {
  let auth = firebase.auth();
  auth
    .signOut()
    .then(function () {
      store.dispatch({ type: "LOGOUT_ATTEMPT" });
    })
    .catch(function (error) {
      console.log(error);
    });
};

/**Helper Functions**/
const loginAttempSuccess = (dispatch, user, successCallBack) => {
  dispatch({ type: "LOGIN_SUCCEEDED", payload: user });
  successCallBack();
  return null;
};

const loginAttempFail = (dispatch, err, failureCallback, pos) => {
  dispatch({ type: "LOGIN_FAILED", payload: err.message });
  setTimeout(function(){ dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: null }); }, 5000);
  failureCallback();
  return null;
};

function getErrorMsg(error) {
  let { code } = error;
  switch (code) {
    case "auth/weak-password":
      error.message = "Password must have atleast 6 characters";
      break;
    case "auth/invalid-email":
      error.message = "Please enter a valid email id";
      break;
    case "auth/user-not-found":
      error.message = "This email id is not registered. Please register.";
      break;
    case "auth/wrong-password":
      error.message = "Incorrect email or password. Please try again.";
      break;
    case "auth/email-already-in-use":
      error.message =
        "This email id is already registered. Please use a different email id";
      break;
    default:
      break;
  }

  return error;
}

function setErrorMsgInForgotPassword(dispatch, msg) {
  dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: msg });

  setTimeout(() => {
    dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: " " });
  }, 3000);
}
/**End of Helper Functions**/
