import React from "react";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";

import "./styles.scss";

const AvatarWithUsername = (props) => {
  const { imageUrl } = props;
  const { user_fullname } = useSelector((state) => state.auth);

  return (
    <div className="avatar-wrapper">
      <ReactSVG className="avatar" src="/assets/profile_icon.svg" />
      {/* <Avatar className="avatar" src={imageUrl || "/assets/profile_icon.svg"} /> */}
      <span className="fullname">{user_fullname}</span>
    </div>
  );
};

export default AvatarWithUsername;
