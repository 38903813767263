export default [
  {
    c: "Adrit Paul",
    spoc: "Megha Deb",
  },
  {
    c: "Amrita Bharadwaj",
    spoc: "Rahul Dubey",
  },
  {
    c: "Annesha Pramanik",
    spoc: "Rashid Jaffrey",
  },
  {
    c: "Anuj Dutta",
    spoc: "Megha Deb",
  },
  {
    c: "Arvind Vishwanath Raikar",
    spoc: "Nidhi Basu",
  },
  {
    c: "Deeba Ahmad",
    spoc: "Megha Deb",
  },
  {
    c: "Deepak Varghese",
    spoc: "Nidhi Basu",
  },
  {
    c: "Devi Prasad Panthala",
    spoc: "Megha Deb",
  },
  {
    c: "Jaya Lekshmy Venugopal",
    spoc: "Rahul Dubey",
  },
  {
    c: "Kanu Agrawal",
    spoc: "Rahul Dubey",
  },
  {
    c: "Karan Lakhani",
    spoc: "Megha Deb",
  },
  {
    c: "Manish Kumar",
    spoc: "Rashid Jaffrey",
  },
  {
    c: "Neena Bahadur",
    spoc: "Rahul Dubey",
  },
  {
    c: "Pankaj Kumar",
    spoc: "Nidhi Basu",
  },
  {
    c: "Pradeep Gnana Nerayanuri",
    spoc: "Nidhi Basu",
  },
  {
    c: "Preetha Simlai Saha",
    spoc: "Nidhi Basu",
  },
  {
    c: "Rajesh Kumar Jha",
    spoc: "Rahul Dubey",
  },
  {
    c: "S. Sundarameenakshi",
    spoc: "Rashid Jaffrey",
  },
  {
    c: "Sharath Kumar",
    spoc: "Rashid Jaffrey",
  },
  {
    c: "Shiv Shanker",
    spoc: "Rashid Jaffrey",
  },
  {
    c: "Shiwangi Sinha",
    spoc: "Nidhi Basu",
  },
  {
    c: "Shreya Ganguly",
    spoc: "Nidhi Basu",
  },
  {
    c: "Sonam Ranjan",
    spoc: "Megha Deb",
  },
  {
    c: "Sudhir Kumar Singh",
    spoc: "Rahul Dubey",
  },
  {
    c: "Sushovan Ghosh",
    spoc: "Rashid Jaffrey",
  },
];
