import React, {useEffect} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from "react-redux";
import LockIcon from '@material-ui/icons/Lock';

let order__ = ['d002', 'd001', 'd003', 'd006', 'd004', 'd005'];
export default (props) => {
    const [md, setMd] = React.useState(0);
    let t = useSelector((state) => state.auth.user_id_token);

    function desktopNav(items, value, handleChange) {

        if (items)
            return <div style={{ width: 215 }}>
                <div style={{ fontSize: 13, padding: '16px 0px 8px', fontWeight: 700, textTransform: 'capitalize' }}>Jump To</div>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value || 0}
                    onChange={handleChange}
                    aria-label="jump-to"
                >
                    {order__.map((el, i) => {
                        let item = items[el];
                        console.log(item);
                        let disabled = !item.isUnitsReady;
                        let a = <div style={{ textAlign: 'left', width: '100%', textTransform: 'initial', display: "flex", alignItems: "center", flexDirection: "row-reverse" }}>
                            {disabled ? <LockIcon fontSize='small' /> : null}
                            <div style={{ fontSize: 16, fontWeight: 700, color: '#A51C01', flexGrow: 1 }}>{item.title}</div>
                        </div>
                        return <Tab disabled={disabled} key={el} label={a} style={{ padding: '16px 16px 16px 0px', borderTop: '1px solid #999' }} />
                    })}
                </Tabs>
            </div>
    }

    function moduleCntr(items, value) {
        return <div style={{ flexGrow: 1 }}>
            {order__.map((el, i) => {
                let item = items[el];

                return <div key={el} role="tabpanel" hidden={value !== i} id={`vertical-tabpanel-${i}`} value={value} index={i} style={{ padding: `0px 0px 0px 96px` }}>
                    {/* <div style={{ fontSize: 13, padding: '16px 0px 8px', fontWeight: 700, textTransform: 'capitalize' }}>Module: {i + 1}</div> */}
                    <div style={{ padding: '0px 0px 16px', fontSize: 20, fontWeight: 700, lineHeight: 1.4 }}>{item.title}</div>
                    <div style={{ display: 'flex', margin: '0px -16px', flexWrap: 'wrap' }}>
                        {Object.keys(item.units || {}).map(el => {
                            let unit = item.units[el];
                            let disabled = !unit.url;
                            let box = (k) => <div key={k} style={{ boxShadow: '0px 3px 6px #00000029', display: 'flex', flexDirection: 'column', backgroundSize: 'contain', background: `${unit.background || "transparent linear-gradient(180deg, #DDDDDD 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box"}`, borderRadius: 15, width: 270, height: 366, opacity: disabled ? 0.5 : 1, justifyContent: "space-between" }}>
                                {/* <div>
                                    <div style={{ fontSize: 13, backgroundColor: '#FFF', padding: '16px 25px', marginTop: 48, fontWeight: 500, textTransform: 'uppercase' }}>
                                        <div style={{ lineHeight: '18px', opacity: 0.89 }}>LEVEL {unit && unit.level ? unit.level : ''}:</div>
                                        <div style={{ lineHeight: '18px', opacity: 0.89 }}>{unit && unit.subTitle ? unit.subTitle : ''}</div>
                                    </div>
                                    <div style={{ color: '#000', fontWeight: 700, padding: 25, fontSize: 18, lineHeight: '26px' }}>{unit && unit.title ? unit.title : ''}</div></div> */}
                                {!k ? <div><img src={unit.thumbnail} height={119} /></div> : <div style={{ margin: 16, alignSelf: 'flex-end' }}><LockIcon /></div>}
                            </div>
                            return unit.url ? <a target="_blank" key={el} href={`${unit.url}&token=${t}`}
                                style={{ display: 'block', textDecoration: 'none', margin: 16, color: '#000' }}>
                                {box()}
                            </a> : <div style={{ margin: 16 }}>{box(el)}</div>
                        })}
                    </div>
                </div>
            })}
        </div>
    }

    const app = useSelector((state) => state.app);
    return <div style={{ display: 'flex' }}>
        <div>{desktopNav(app.dimensions, md, (ev, val) => { setMd(val) })}</div>
        {moduleCntr(app.dimensions, md)}
    </div>
}