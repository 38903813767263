import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import Registration from "../../components/RegistrationForm";
import { registerAttempt, setErrMsg, resetLoginPage } from "./redux_actions";


let Register = class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      firstname: "",
      lastname: "",
      name: "",
      phone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      location: "",
      title: "",
      accesscode: "",
      accessValidTillDate: "",
      role: [],
      parentid: "",
      organizationid: "",
    };
    
    this.notLoggedIn = (p)=><div>
          <Registration
            registerAttempt={this.props.registerAttempt}
            authMsg={p.auth.loginErrorMsg}
          />
        </div>

    this.loggedIn = (
      <Redirect
        to={{
          pathname: "/dashboard",
        }}
      />
    );
  }

  componentDidMount() {
    //reset all the values in the state;
    this.props.resetLoginPage();
  }

  render() {
    //this.props.requestPermissionToNotify();
    console.log(this.props.auth);
    if (
      this.props.location.pathname === "/channel" &&
      this.props.auth.isLoggedIn
    ) {
      return;
    } else if (this.props.auth.isLoggedIn) {
      return this.loggedIn;
    } else {
      return this.notLoggedIn(this.props);
    }
  }
};


const mapStateToProps = (state) => ({
  auth: state.auth,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerAttempt,
      setErrMsg,
      //requestPermissionToNotify,
      resetLoginPage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Register);
