import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";

import "./styles.scss";

const SaveExit = () => {
  return (
    <div className="save-exit-wrapper">
      <Typography className="exit-message">
        Your responses have been saved.
        <br />
        Please complete the test
        <br />
        in order to view your report.
      </Typography>
      <div>
        <Button
          component={Link}
          variant="contained"
          color="primary"
          size="large"
          className="b-home"
          to="/dashboard"
        >
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default SaveExit;
