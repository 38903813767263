import React from "react";

function getSectorPath(x, y, outerDiameter, a1, a2) {
  const degtorad = Math.PI / 180;
  const halfOuterDiameter = outerDiameter / 2;
  const cr = halfOuterDiameter - 0;
  const cx1 = (Math.cos(degtorad * a2) * cr) + x;
  const cy1 = (-Math.sin(degtorad * a2) * cr) + y;
  const cx2 = (Math.cos(degtorad * a1) * cr) + x;
  const cy2 = (-Math.sin(degtorad * a1) * cr) + y;

  return "M" + x + " " + y + " " + cx1 + " " + cy1 + " A" + cr + " " + cr + " 0 0 1 " + cx2 + " " + cy2 + "Z";;
}

let Radar = ({ data }) => {
  let r = [80, 130, 180];
  let R = [80, 130, 180, 180];
  let _R = [180, 130, 80, 30];
  data = data || {};
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={400}
    height={400}
    viewBox="0 0 400 400"
  >
    <circle cx="200" cy="200" r={r[2]} fill="#d0c0a0" />
    <circle cx="200" cy="200" r={r[1]} fill="#d4c6a9" stroke="#dbcfb7" strokeWidth="2" />
    <circle cx="200" cy="200" r={r[0]} fill="#d9ccb3" stroke="#dbcfb7" strokeWidth="2" />
   
    {Object.keys(data || {}).map((el, i) => {
      let angle = (330 - 60 * i);
      let _angle = (330 - 60 * i + 60);
      let lvl = (data[el].lvl).split("")[3];
      return _R.map((l, k) => {
        let j = 3 - k;
        let __angle = (60 * i) * 0.0174533;
        let x = 200 + (l - 20) * Math.cos(__angle), y = 200 + (l - 20) * Math.sin(__angle);
        if (j <= lvl) {
          return <g key={`i${l}-${j}`} >
            <path d={getSectorPath(200, 200, l * 2, angle, _angle)} strokeWidth={1} stroke="#FFF" fill={j == 3 ? "#ffbc36" : j == 2 ? "#ffc24a" : j == 1 ? "#ffc95e" : j == 0 ? "#ffc95e" : "#e1e1e1"} />
            {j==lvl?<circle key={`${l}-${j}`} cx={x} cy={y} r={6} fill={"#00be52"} strokeWidth="2" stroke="#FFF" />:null}
          </g>
        }
        return null;
      })
    })};

    {Object.keys(data || {}).map((el, i) => {
      let angle = (60 * i - 30) * 0.0174533;
      return <path key={`radar-${el}`} d={`M 200 200 l ${r[2] * Math.cos(angle)} ${r[2] * Math.sin(angle)}`} strokeWidth="4" stroke="#FFF" />
    })}

    <circle cx="200" cy="200" r={30} fill="#fff" />
    <image href="/assets/radar-text&man.svg" height="400" width="400" />
  </svg>
}

export default Radar;
