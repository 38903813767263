import React, { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import EvolveLayout from "../Evolve";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./styles.scss";

import ModalWrapper from "../Modal";
import VideoPlayer from "../VideoPlayer";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PlayCircleOutline } from "@material-ui/icons";
import {updateUserEngagement} from "../../containers/app/redux_actions";
const WalkThrough = (props) => {
  let [open, setOpen] = useState(false);
  let [media, setMedia] = useState(null);

  const walkthroughTitles = {
    ownership: "Explore what it means to take ownership",
    growing: "Explore what it means to grow your aspirations",
    responsiveness: "Explore what it means to develop responsiveness",
    collaboration: "Explore what it means to collaborate effectively",
    valuecreator: "Explore what it means to become a value creator",
    evolving: "Explore what it means to evolve professionally",
  };

  let { dimension } = useParams();
  const {user_uid} = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);
  const dimensions = app.dimensions;
  const circleClickHandler = (media) => {
    setMedia(media);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let presentDimension = _.find(dimensions, (d) => {
    return d.id === dimension;
  });

  return (
    <EvolveLayout presentDimension={presentDimension}>
      <div className="walkthrough-wrapper height-100">
        <Typography className="w-heading">
          {walkthroughTitles[dimension]}
        </Typography>
        <div className="height-100 dimensions-svg">
          <Grid container>
            {presentDimension &&
              Object.keys(presentDimension.walkthrough).map((w, i) => {
                let dim = presentDimension.walkthrough[w];
                return (
                  <Grid
                    key={w}
                    item
                    md={6}
                    lg={6}
                    sm={12}
                    className="grid"
                    onClick={(e) => { updateUserEngagement({uid: user_uid, dim:dimension, type:"walkthrough", unit: w}); circleClickHandler(dim)}}
                  >
                    <div className="w-cover">
                      <LazyLoadImage src={dim.cover} />
                      <div className="play-btn">
                        <PlayCircleOutline />
                      </div>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
        {media && (
          <ModalWrapper open={open} handleClose={handleClose}>
            {/* <div style={{ width: 912, height: 510 }}> */}
            <VideoPlayer mediaUrl={media.url} imgUrl={media.Poster} />
            {/* </div> */}
          </ModalWrapper>
        )}
      </div>
    </EvolveLayout>
  );
};

export default WalkThrough;
