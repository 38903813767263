import _ from "lodash";

const INITIAL_STATE = {
  testId: "fyidom",
  testDetails: {},
  questionDetails: {},
  userTestDetails: {},
  dimensions: {},
  reviewOn: false,
  settings: {
    clientLogo: "https://tatasteel-futurereadyexp.s3.ap-south-1.amazonaws.com/1280px-Tata_Steel_Logo.svg.svg"
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "SET_TEST_DETAILS":
      return { ...state, testDetails: action.testDetails };
    case "SET_QUESTION_DETAILS":
      const userTestDetails = state.userTestDetails || {
        config: {},
        questions: {},
      };
      const questions =
        userTestDetails.config && userTestDetails.config.questions
          ? userTestDetails.config.questions
          : null;
      const currentSection = userTestDetails.config
        ? userTestDetails.config.currentSection
        : null;
      const currentSectionQuestions = questions
        ? questions[currentSection]
        : {};
      const questionsLength = Object.keys(currentSectionQuestions).length;

      if (
        userTestDetails &&
        userTestDetails.responses &&
        Object.keys(userTestDetails.responses).length > 0
      ) {
        state.isTestStarted = true;
      } else {
        state.isTestStarted = false;
      }

      if (
        state.userTestDetails &&
        state.userTestDetails.responses &&
        Object.keys(state.userTestDetails.responses).length ===
          Object.keys(state.testDetails.questions).length
      ) {
        state.isTestCompleted = true;
        state.isTestStarted = false;
      } else {
        state.isTestCompleted = false;
      }

      return {
        ...state,
        questionDetails: {
          ...state.questionDetails,
          [action.data.qId]: action.data.qDetail,
        },
      };
    case "SET_USER_TEST_DETAILS":
      return { ...state, userTestDetails: action.userTestDetails };
    case "SET_USER_RESPONSE":
      return {
        ...state,
        userTestDetails: {
          ...state.userTestDetails,
          responses: {
            ...state.userTestDetails.responses,
            [action.data.queId]: action.data.response,
          },
        },
      };
    case "SET_DIMENSION_DETAILS":
      return { ...state, dimensions: action.dimensions };
    case "UPDATE_TEST_PROGRESS":
      state.isTestCompleted = action.data.isDone;
      return { ...state };
    case "JUMP_TO_QUESTION":
      let _currentSection = state.userTestDetails.config
        ? state.userTestDetails.config.currentSection
        : null;
      let currentQuestionList = state.userTestDetails.config
        ? state.userTestDetails.config.questions[_currentSection].questions
        : [];
      let quesIdOrders = Object.keys(currentQuestionList).sort((a, b) => {
        return currentQuestionList[a].order - currentQuestionList[b].order;
      });

      state.userTestDetails.config.currentQuestionOrder = action.data.oId;
      state.userTestDetails.config.currentQuestion =
        quesIdOrders[action.data.oId - 1];

      return { ...state };
    case "SET_REVIEW":
      return { ...state, reviewOn: action.data.on };
    case "UPDATE_SETTINGS":
      return { ...state, settings: {...action.settings} };
    default:
      return state;
  }
};
