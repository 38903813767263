import React from "react";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./styles.scss";

import { ReactComponent as BgImage } from "./Assessment_bg_image.svg";

import AvatarWithUsername from "../AvatarWithUsername";
import { useSelector } from "react-redux";

const StartAssessment = ({ children }) => {
  const app = useSelector((state) => state.app);

  return (
    <div className="screen-cntr">
      <div className="start-assessment-wrapper">
        <div></div>
        <div className="heading">
          <div>
            <Link className="b-btn" to="/dashboard">
              Back to Home
            </Link>
          </div>
          <div className="profile">
            <AvatarWithUsername />
          </div>
        </div>
        <div className="assessment-container">
          <Typography className="start-title">
            Welcome {app.isTestStarted ? "back" : ""} to the Future-Readiness
            Cognitive Test
          </Typography>

          {app.isTestStarted && (
            <Button
              component={Link}
              to="/assessment"
              color="primary"
              variant="contained"
              className="c-btn"
            >
              Continue where i left off
            </Button>
          )}

          <Typography className="list-heading">
            How to approach the test
          </Typography>
          <ul className="list">
            <li>
              There are 108 questions, each with two options. You need to choose
              your more preferred option.
            </li>
            <li>
              There is no correct or wrong answer. They are different ways of
              thinking or approaches to the same situation.
            </li>
            <li>
              To make your choice imagine the situation/scenario even if you
              haven’t faced it before.
            </li>
            <li>
              It is recommended that you complete the test within 75 mins.
            </li>
            <li>
              Try not to spend too much time over thinking or analysing your
              response.
            </li>
            <li>Your responses to questions will be kept confidential. </li>
          </ul>

          <Typography className="list-heading note">
            How to use this test
          </Typography>
          <ul className="list">
            <li>
              All questions are compulsory. You cannot Skip a question. However,
              if you are unsure of any response, note down the Q. No. so that
              you can revisit at the time of Review.
            </li>
            <li>
              You get to Review your responses three times – after Q. 36, Q. 72
              and Q. 108. Once you confirm, your responses will be considered
              final for Q.1 to 36 and so on for each set.
            </li>
            <li>
              Do remember that this is not an evaluation but a test of your
              thinking and preferences to help you develop. In such matters,
              ‘honesty is the best policy’.
            </li>
          </ul>
          {!app.isTestStarted && (
            <div className="sb-btn">
              <Button
                component={Link}
                className="start-btn"
                size="large"
                variant="contained"
                color="primary"
                to="/assessment"
              >
                Start
              </Button>
            </div>
          )}
        </div>
        {/* <BgImage className="bg-image" /> */}
        {/* <div className="logo-wrapper">
        <img
          src="/assets/illumine-branding-assessment.svg"
          alt="illumine_logo"
        />
      </div> */}
        <div className="support-text">
          Facing a technical issue? Write-in to
          <Link
            className="span"
            to="mailto:'support@futurereadyxp.in'"
            underline="none"
          >
            {" "}
            support@futurereadyxp.in
          </Link>
        </div>
      </div>
    </div>
  );
};
export default StartAssessment;
