import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./LoginForm.scss";

class Loginform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      fields: {},
      errors: {},
      label: "",
    };

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }

  handleValidation(eml, pwd) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //Email
    if (!eml) {
      formIsValid = false;
      errors["user-email"] = "Email cannot be empty";
      this.setState({ label: "Email can not be empty" });
    }
    //Password
    if (!pwd) {
      formIsValid = false;
      errors["user-password"] = "Password cannot be empty";
      this.setState({ label: "Password can not be empty" });
    }

    if (typeof eml !== "undefined") {
      let lastAtPos = eml.lastIndexOf("@");
      let lastDotPos = eml.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          eml.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          eml.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["user-email"] = "Email is not valid";
        this.setState({ label: "Email can not be empty" });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { classes } = this.props;
    const toRegister = (props) => <Link to="../auth/register" {...props} />;
    const toResetPassword = (props) => (
      <Link to="../auth/reset-password" {...props} />
    );
    return (
      <div className={"auth-form-cntr"}>
        <form className="form">
          <Grid container className="container container-1">
            <Grid item md={12}>
              <Typography className="form-title">Login</Typography>
            </Grid>
          </Grid>

          <Grid container className="container container-2">
            <Grid item md={12}>
              <FormControl fullWidth className={"input-cntr"}>
                <InputLabel htmlFor="adornment-password">Email</InputLabel>
                <Input
                  id="user-email"
                  label="Your Registered Email ID"
                  type="text"
                  autoComplete="on"
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className="container container-3">
            <Grid item md={12}>
              <FormControl fullWidth className={"input-cntr"}>
                <InputLabel htmlFor="adornment-password">Password</InputLabel>
                <Input
                  id="user-password"
                  label="Password"
                  autoComplete="off"
                  type={this.state.showPassword ? "text" : "password"}
                  value={this.state.password}
                  onChange={this.handleChange("password")}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className="container container-4">
            <Grid item md={12}>
              <div className="rf-wrapper">
                {/* <div className="rememberme-container">
                  <FormControlLabel
                    control={<Checkbox name="rememberme" />}
                    label="Remember me"
                  />
                </div> */}
                <div className="forgotpassword-container">
                  <Link to="/auth/reset-password">Forgot password?</Link>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container className="container container-5">
            <Grid item md={8}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                className=""
                onClick={() => {
                  let { email, password } = this.state;
                  let chkVal = this.handleValidation(email, password);

                  if (chkVal) {
                    this.props.loginAttempt({
                      email,
                      password,
                      successCallBack: () => {
                        //alert('success');
                      },
                      failureCallback: () => {
                        //alert('failed');
                      },
                    });
                  } else {
                    this.setState({
                      label:
                        "Either Email or Password is incorrect. Please try again.",
                    });
                  }
                }}
              >
                Enter
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button fullWidth component={Link} to="/auth/register" className="register-btn">
                Register
              </Button>
            </Grid>
          </Grid>

          {this.props.authMsg && (
            <div className={"loginform-cntr"}>
              <div className="errorMsg">
                <label> {this.state.label} </label>
              </div>

              <Typography
                className="errorMsg"
                variant="caption"
                gutterBottom
                align="center"
                color="secondary"
              >
                {this.props.authMsg}
              </Typography>

              <FormControl className=""></FormControl>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default Loginform;
